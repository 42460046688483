import React from "react";
import { connect } from "react-redux";
import {
  Field,
  formValueSelector,
  reduxForm,
} from "redux-form";
import {
  Button,
  DatePicker,
  Input,
  Select,
  Textarea,
} from "components";

import { ReactComponent as InfoIcon } from "icons/info.svg";
import Editor from "components/EditorSimple";
import { updateArticle } from "actions/articles";
import article_kind_options from "utils/options/article_kind_options";

const InputField = (props) => <Input {...props} />;
const TextAreaField = (props) => <Textarea {...props} />;
const SelectField = (props) => <Select {...props} />;

const selector = formValueSelector(
  "article_edit_basic_info"
);

const BasicInfo = ({
  article,
  change,
  pristine,
  handleSubmit,
  updateArticle,
  date,
  kind,
}) => {
  const onSubmit = (values) =>
    updateArticle(article.id, values);
  return (
    <div className="dashboard">
      <header className="dashboard__header">
        <div className="icon__container">
          <InfoIcon />
        </div>
        <h2 className="dashboard__title heading">
          Podstawowe informacje
        </h2>
        <Button
          type="save"
          disabled={pristine}
          style={{ marginLeft: "15px" }}
          text="Zapisz"
          onClick={handleSubmit(onSubmit)}
        />
      </header>
      <div className="dashboard__form">
        <form noValidate>
          <Field
            key={kind}
            label="Rodzaj"
            name="kind"
            component={SelectField}
            options={article_kind_options}
            handleChange={(value) => change("kind", value)}
            defaultValue={article_kind_options.find(
              ({ value }) => value === kind
            )}
          />
          <DatePicker
            label="Data"
            value={date}
            onChange={(value) =>
              change("date", new Date(value))
            }
          />
          <Field
            label="Tytuł"
            name="title"
            component={InputField}
          />
          <Field
            label="Meta title"
            name="meta_title"
            component={InputField}
          />
          <Field
            label="Meta description"
            name="meta_description"
            component={InputField}
          />
          <Field
            label="Krótki opis (do kafelka)"
            name="short_description"
            component={TextAreaField}
          />
          <Editor
            label="Treść"
            name="content"
            change={change}
            value={article?.content}
          />
        </form>
      </div>
    </div>
  );
};

export default connect(
  ({ article, ...state }) => ({
    article: article.data,
    initialValues: {
      title: article?.data?.title,
      kind: article?.data?.kind,
      date: article?.data?.date
        ? new Date(article?.data?.date)
        : null,
      meta_title: article?.data?.meta_title,
      meta_description: article?.data?.meta_description,
      short_description: article?.data?.short_description,
      content: article?.data?.content,
    },
    date: selector(state, "date"),
    kind: selector(state, "kind"),
  }),
  (dispatch) => ({
    updateArticle: (id, values) =>
      dispatch(updateArticle(id, values)),
  })
)(
  reduxForm({
    form: "article_edit_basic_info",
    enableReinitialize: true,
  })(BasicInfo)
);
