import React from "react";
import { Field, reduxForm } from "redux-form";
import { Main, Input, ActionBar } from "components";
import { addPage } from "actions/pages";

import { ReactComponent as InfoIcon } from "icons/info.svg";
import Editor from "components/EditorSimple";

const InputField = (props) => <Input {...props} />;

const required_fields = [
  "title",
  "meta_title",
  "meta_description",
];

const validate = (values) => {
  const errors = {};
  required_fields.forEach((key) => {
    if (!values[key]) {
      errors[key] = "Wymagane";
    }
  });

  return errors;
};

const NewPage = ({ handleSubmit, history, change }) => {
  const onSubmit = async (values) => {
    try {
      const { id } = await addPage(values);
      history.push(`/pages/${id}`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Main
      breadcrumbs={[
        {
          name: "Strony statyczne",
          href: "/pages",
        },
        { name: "Dodaj stronę statyczną" },
      ]}
      title="Dodaj stronę statyczną">
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <div className="dashboard">
            <header className="dashboard__header">
              <div className="icon__container">
                <InfoIcon />
              </div>
              <h2 className="dashboard__title heading">
                Podstawowe informacje
              </h2>
            </header>

            <div className="dashboard__form">
              <form noValidate>
                <Field
                  label="Tytuł"
                  name="title"
                  component={InputField}
                />
                <Field
                  label="Meta title"
                  name="meta_title"
                  component={InputField}
                />
                <Field
                  label="Meta description"
                  name="meta_description"
                  component={InputField}
                />
                <Editor
                  label="Treść"
                  name="content"
                  change={change}
                  value=""
                />
              </form>
            </div>
          </div>
        </div>
      </div>
      <ActionBar
        saveAction={handleSubmit(onSubmit)}
        cancel_path="/pages"
      />
    </Main>
  );
};

export default reduxForm({
  form: "create_page",
  enableReinitialize: true,
  validate,
})(NewPage);
