import React from "react";
import {
  Button,
  ImageUploader,
  Input,
  Modal,
  Select,
} from "components";
import { connect } from "react-redux";
import {
  Field,
  formValueSelector,
  reduxForm,
  SubmissionError,
} from "redux-form";
import { createCourseModuleWidget } from "actions/course_module";
import course_module_widget_options from "utils/options/course_module_widget_options";
import Editor from "components/EditorSimple";

const selector = formValueSelector(
  "create_course_module_widget"
);

const InputField = (props) => <Input {...props} />;
const SelectField = (props) => <Select {...props} />;

const CreateWidget = ({
  handleClose,
  form_values,
  change,
  pristine,
  handleSubmit,
  createCourseModuleWidget,
}) => {
  const onSubmit = (values) =>
    createCourseModuleWidget(values).catch((error) => {
      throw new SubmissionError(error);
    });

  return (
    <Modal
      handleClose={handleClose}
      title="Dodaj widget"
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <Button
            type="save"
            text="Potwierdź"
            disabled={pristine}
            onClick={handleSubmit(onSubmit)}
          />
        </>
      }>
      <form noValidate>
        <Field
          label="Pozycja"
          name="position"
          type="number"
          component={InputField}
        />
        <Field
          key={form_values.type}
          label="Typ"
          name="type"
          component={SelectField}
          options={course_module_widget_options}
          handleChange={(value) => change("type", value)}
          defaultValue={course_module_widget_options.find(
            ({ value }) => form_values.type === value
          )}
        />
        {form_values.type ===
          "CourseModuleWidget::Text" && (
          <Editor name="text" change={change} value="" />
        )}
        {form_values.type ===
          "CourseModuleWidget::Video" && (
          <ImageUploader
            accept="video/mp4,video/x-m4v,video/*"
            handleFile={(file) => change("video", file)}
          />
        )}
        {form_values.type ===
          "CourseModuleWidget::File" && (
          <ImageUploader
            accept="application/pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword"
            handleFile={(file) => change("file", file)}
          />
        )}
        {form_values.type ===
          "CourseModuleWidget::Image" && (
          <ImageUploader
            accept="image/*"
            handleFile={(file) => change("image", file)}
          />
        )}
        {form_values.type ===
          "CourseModuleWidget::Youtube" && (
          <Field
            label="Youtube ID"
            name="youtube_id"
            component={InputField}
          />
        )}
      </form>
    </Modal>
  );
};

export default connect(
  (state) => ({
    initialValues: {
      course_module_id: state.course_module.data.id,
      type: "CourseModuleWidget::Text",
    },
    form_values: {
      type: selector(state, "type"),
    },
  }),
  (dispatch) => ({
    createCourseModuleWidget: (values) =>
      dispatch(createCourseModuleWidget(values)),
  })
)(
  reduxForm({
    form: "create_course_module_widget",
    enableReinitialize: true,
  })(CreateWidget)
);
