import { toastr } from "react-redux-toastr";
import { client, handleErrors } from "utils/api";

export const getUsers = ({
  page = 1,
  per_page = 10,
  loading = true,
  query,
}) => async (dispatch) => {
  try {
    loading && dispatch({ type: "USERS_LOADING" });

    const { data, meta } = await client.get(
      `/users?sort[order]=asc&sort[column]=id&page=${page}&per_page=${per_page}${
        query ? `&search=${query}` : ""
      }`
    );
    dispatch({
      type: "USERS_SUCCESS",
      payload: { data, meta },
    });
  } catch (error) {
    dispatch({ type: "USERS_FAILURE" });
    toastr.error(
      "Błąd",
      "Wystąpił błąd podzczas pobierania"
    );
  }
};

export const getUser = (id, load = true) => async (
  dispatch
) => {
  try {
    if (load) {
      dispatch({ type: "USER_LOADING" });
    }

    const { data } = await client.get(`/users/${id}`);

    dispatch({
      type: "USER_SUCCESS",
      payload: { data },
    });
  } catch (error) {
    dispatch({ type: "USER_FAILURE" });
    toastr.error(
      "Błąd",
      "Wystąpił błąd podzczas pobierania"
    );
  }
};

export const createUser = async (values) => {
  try {
    const { id } = await client.post("/users", values);
    toastr.success("Sukces", "Użytkownik zostal dodany");

    return id;
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd");
    throw handleErrors(error?.response?.data?.errors);
  }
};

export const updateUser = (id, values) => async (
  dispatch
) => {
  try {
    const { data } = await client.put(
      `/users/${id}`,
      values
    );
    toastr.success(
      "Sukces",
      "Użytkownik zostal zaktualizowany"
    );
    dispatch({
      type: "USER_SUCCESS",
      payload: { data },
    });
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd");
    throw error;
  }
};

export const deleteUser = async (id) => {
  try {
    await client.delete(`/users/${id}`);
    toastr.success("Sukces", "Użytkownik został usunięty");
    return id;
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd.");
    throw error;
  }
};
