import React from "react";
import {
  Field,
  reduxForm,
  SubmissionError,
} from "redux-form";
import { Main, Input, ActionBar } from "components";
import { createCourse } from "actions/courses";

import { ReactComponent as InfoIcon } from "icons/info.svg";
import { EditorSimple, ImageUploader } from "components";

const InputField = (props) => <Input {...props} />;

const CourseNew = ({ handleSubmit, history, change }) => {
  const onSubmit = async (values) => {
    try {
      const { id } = await createCourse(values);
      history.push(`/courses/${id}`);
    } catch (error) {
      throw new SubmissionError(error);
    }
  };

  return (
    <Main
      breadcrumbs={[
        { name: "Kursy", href: "/courses" },
        { name: "Dodaj kurs" },
      ]}
      title="Dodaj kurs">
      <form noValidate>
        <div className="dashboard-wrapper-flex">
          <div className="column-half">
            <div className="dashboard">
              <header className="dashboard__header">
                <div className="icon__container">
                  <InfoIcon />
                </div>
                <h2 className="dashboard__title heading">
                  Podstawowe informacje
                </h2>
              </header>
              <div className="dashboard__form">
                <Field
                  label="Tytuł"
                  name="title"
                  component={InputField}
                />
                <EditorSimple
                  label="Treść"
                  name="description"
                  change={change}
                  value=""
                />
              </div>
            </div>
          </div>
          <div className="column-half">
            <div className="dashboard">
              <header className="dashboard__header">
                <div className="icon__container">
                  <InfoIcon />
                </div>
                <h2 className="dashboard__title heading">
                  Miniaturka
                </h2>
              </header>
              <div className="dashboard__form">
                <ImageUploader
                  handleFile={(file) =>
                    change("image", file)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      <ActionBar
        saveAction={handleSubmit(onSubmit)}
        cancel_path="/courses"
      />
    </Main>
  );
};

const validate = (values) => {
  const errors = {};
  ["title"].forEach((key) => {
    if (!values[key]) {
      errors[key] = "Wymagane";
    }
  });

  return errors;
};

export default reduxForm({
  form: "create_page",
  enableReinitialize: true,
  validate,
})(CourseNew);
