import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  ActionBar,
  Main,
  ModalDelete,
  PageLoader,
} from "components";
import { Field, reduxForm } from "redux-form";
import { Input, Checkbox } from "components";
import {
  updateUser,
  getUser,
  deleteUser,
} from "actions/users";

import { ReactComponent as InfoIcon } from "icons/info.svg";

const InputField = (props) => <Input {...props} />;
const CheckField = (props) => <Checkbox {...props} />;

const UpdateUser = ({
  user,
  match: {
    params: { id },
  },
  change,
  pristine,
  history,
  getUser,
  updateUser,
  handleSubmit,
}) => {
  const [
    is_open_delete_modal,
    handleOpenDeleteModal,
  ] = useState(false);

  useEffect(() => {
    getUser(id);
  }, [id]);

  const onSubmit = (values) =>
    updateUser(id, values).then(() =>
      change("password", "")
    );

  const handleDelete = async () => {
    try {
      await deleteUser(id);
      history.push("/users");
    } catch (error) {
      console.log(error);
    }
  };

  const { status, data } = user;

  if (status === "invalid" || status === "loading") {
    return <PageLoader />;
  }

  return (
    <Main
      breadcrumbs={[
        {
          name: "Użytkownicy",
          href: "/users",
        },
        { name: data?.full_name },
      ]}
      title={data?.full_name}>
      {is_open_delete_modal && (
        <ModalDelete
          name={data?.full_name}
          handleClose={() => handleOpenDeleteModal(false)}
          handleDelete={handleDelete}
        />
      )}
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <div className="dashboard">
            <header className="dashboard__header">
              <div className="icon__container">
                <InfoIcon />
              </div>
              <h2 className="dashboard__title heading">
                Podstawowe informacje
              </h2>
            </header>
            <div className="dashboard__form">
              <form
                noValidate
                onSubmit={handleSubmit(onSubmit)}>
                <div className="form-row">
                  <Field
                    label="Imie"
                    name="first_name"
                    component={InputField}
                  />
                  <Field
                    label="Nazwisko"
                    name="last_name"
                    component={InputField}
                  />
                </div>
                <div className="form-row">
                  <Field
                    label="Adres e-mail"
                    name="email"
                    component={InputField}
                  />
                  <Field
                    label="Zmień hasło"
                    name="password"
                    component={InputField}
                  />
                </div>
                <div className="form-row">
                  <Field
                    label="Aktywny"
                    name="is_active"
                    component={CheckField}
                  />
                </div>
                <div className="form-row">
                  <Field
                    label="Administrator"
                    name="is_admin"
                    component={CheckField}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ActionBar
        deleteAction={() => handleOpenDeleteModal(true)}
        saveAction={handleSubmit(onSubmit)}
        disable_save={pristine}
        cancel_path="/users"
      />
    </Main>
  );
};

const required_fields = [
  "first_name",
  "last_name",
  "email",
];

const validate = (values) => {
  const errors = {};
  required_fields.forEach((key) => {
    if (!values[key]) {
      errors[key] = "Wymagane";
    }
  });

  if (!values.email) {
    errors.email = "Wymagane";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
      values.email
    )
  ) {
    errors.email = "Niepoprawny adres email";
  }

  return errors;
};

export default connect(
  ({ user }) => ({
    user,
    initialValues: {
      first_name: user?.data?.first_name,
      last_name: user?.data?.last_name,
      email: user?.data?.email,
      is_active: user?.data?.is_active,
      is_admin: user?.data?.is_admin,
    },
  }),
  (dispatch) => ({
    getUser: (id) => dispatch(getUser(id)),
    updateUser: (id, values) =>
      dispatch(updateUser(id, values)),
  })
)(
  reduxForm({
    form: "user_edit",
    enableReinitialize: true,
    validate,
  })(UpdateUser)
);
