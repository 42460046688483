import React from "react";
import Dropzone from "react-dropzone-uploader";

const ImageUploader = ({
  handleFile,
  accept = "image/*",
}) => {
  const handleChangeStatus = ({ file }, status) => {
    if (status === "done") {
      handleFile(file);
    }
  };

  return (
    <Dropzone
      onChangeStatus={handleChangeStatus}
      SubmitButtonComponent={null}
      maxFiles={1}
      multiple={false}
      canCancel={false}
      inputContent={() => (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            style={{ marginBottom: 5 }}>
            <rect fill="none" width="24" height="24" />
            <path
              fill="#5d78ff"
              opacity="0.3"
              d="M2,13a1.075,1.075,0,0,1,1-1,1.075,1.075,0,0,1,1,1v5a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V13a1,1,0,0,1,2,0v5a4,4,0,0,1-4,4H6a4,4,0,0,1-4-4Z"
            />
            <rect
              fill="#5d78ff"
              opacity="0.3"
              width="2"
              height="14"
              rx="1"
              transform="translate(11 2)"
            />
            <path
              fill="#5d78ff"
              d="M12.036,3.378,7.707,7.707A1,1,0,0,1,6.293,6.293l5-5a1,1,0,0,1,1.376-.036l5,4.5a1,1,0,0,1-1.338,1.487Z"
            />
          </svg>
          <p
            style={{
              fontSize: "15px",
              lineHeight: "23px",
              fontWeight: 300,
              color: "#595D6E",
            }}>
            Przenieś plik tutaj lub kliknij, aby go dodać.
          </p>
        </>
      )}
      inputWithFilesContent="Dodaj"
      styles={{
        dropzone: {
          minHeight: 140,
          padding: 10,
          border: "2px dashed #EBEDF2",
          borderRadius: 0,
          overflow: "auto",
          flexDirection: "row",
          flexFlow: "wrap",
          justifyContent: "center",
        },
        inputLabel: {
          flexDirection: "column",
          justifyContent: "center",
        },
        previewImage: {
          maxHeight: "initial",
          maxWidth: "calc(100% - 15px)",
        },
        preview: {
          padding: 10,
          border: "none",
          display: "inline-flex",
          width: "auto",
          alignItems: "flex-start",
        },
        inputLabelWithFiles: {
          margin: "auto",
          backgroundColor: "#44B2F1",
          color: "#fff",
          borderRadius: "5px",
        },
      }}
      accept={accept}
      disabled={(files) =>
        files.some((f) =>
          [
            "preparing",
            "getting_upload_params",
            "uploading",
          ].includes(f.meta.status)
        )
      }
    />
  );
};

export default ImageUploader;
