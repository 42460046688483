import React from "react";
import { connect } from "react-redux";
import { ActionBar, Main } from "components";
import {
  Field,
  reduxForm,
  SubmissionError,
} from "redux-form";
import { Input, Checkbox } from "components";
import { createUser } from "actions/users";

import { ReactComponent as InfoIcon } from "icons/info.svg";

const InputField = (props) => <Input {...props} />;
const CheckField = (props) => <Checkbox {...props} />;

const required_fields = [
  "first_name",
  "last_name",
  "email",
  "password",
];

const validate = (values) => {
  const errors = {};
  required_fields.forEach((key) => {
    if (!values[key]) {
      errors[key] = "Wymagane";
    }
  });

  if (!values.email) {
    errors.email = "Wymagane";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
      values.email
    )
  ) {
    errors.email = "Niepoprawny adres email";
  }

  return errors;
};

const CreateUser = ({
  pristine,
  history,
  handleSubmit,
}) => {
  const onSubmit = (values) =>
    createUser(values)
      .then((id) => history.push(`/users/${id}`))
      .catch((error) => {
        throw new SubmissionError(error);
      });

  return (
    <Main
      breadcrumbs={[
        {
          name: "Użytkownicy",
          href: "/users",
        },
        { name: "Dodaj użytkownika" },
      ]}
      title="Dodaj użytkownika">
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <div className="dashboard">
            <header className="dashboard__header">
              <div className="icon__container">
                <InfoIcon />
              </div>
              <h2 className="dashboard__title heading">
                Podstawowe informacje
              </h2>
            </header>
            <div className="dashboard__form">
              <form
                noValidate
                onSubmit={handleSubmit(onSubmit)}>
                <div className="form-row">
                  <Field
                    label="Imie"
                    name="first_name"
                    component={InputField}
                  />
                  <Field
                    label="Nazwisko"
                    name="last_name"
                    component={InputField}
                  />
                </div>
                <div className="form-row">
                  <Field
                    label="Adres e-mail"
                    name="email"
                    component={InputField}
                  />
                  <Field
                    label="Hasło"
                    name="password"
                    type="password"
                    component={InputField}
                  />
                </div>
                <div className="form-row">
                  <Field
                    label="Aktywny"
                    name="is_active"
                    component={CheckField}
                  />
                </div>
                <div className="form-row">
                  <Field
                    label="Administrator"
                    name="is_admin"
                    component={CheckField}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ActionBar
        saveAction={handleSubmit(onSubmit)}
        disable_save={pristine}
        cancel_path="/users"
      />
    </Main>
  );
};

export default connect(({ current_user }) => ({
  current_user,
}))(
  reduxForm({
    form: "owner_create",
    enableReinitialize: true,
    validate,
  })(CreateUser)
);
