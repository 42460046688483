import { toastr } from "react-redux-toastr";
import { client, handleErrors } from "utils/api";

export const getArticles = ({
  page = 1,
  per_page = 10,
  loading = true,
  query,
}) => async (dispatch) => {
  try {
    if (loading) {
      dispatch({ type: "ARTICLES_LOADING" });
    }

    const { data, meta } = await client.get(
      `/articles?page=${page}&per_page=${per_page}${
        query ? `&search=${query}` : ""
      }`
    );
    dispatch({
      type: "ARTICLES_SUCCESS",
      payload: { data, meta },
    });
  } catch (error) {
    dispatch({ type: "ARTICLES_FAILURE" });
    toastr.error(
      "Błąd",
      "Wystąpił błąd podzczas pobierania"
    );
  }
};

export const getArticle = (id) => async (dispatch) => {
  try {
    dispatch({ type: "ARTICLE_LOADING" });
    const { data } = await client.get(`/articles/${id}`);

    dispatch({
      type: "ARTICLE_SUCCESS",
      payload: { data },
    });
  } catch (error) {
    dispatch({ type: "ARTICLE_FAILURE" });
    toastr.error(
      "Błąd",
      "Wystąpił błąd podzczas pobierania"
    );
  }
};

export const createArticle = async (values) => {
  try {
    const form_data = new FormData();
    Object.keys(values).forEach((key) => {
      form_data.append(key, values[key]);
    });

    const { id } = await client.post(
      "/articles",
      form_data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    toastr.success("Sukces", "Artykuł został utworzony");
    return id;
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd");
    throw handleErrors(error?.response?.data?.errors);
  }
};

export const updateArticle = (id, values) => async (
  dispatch
) => {
  try {
    const { data } = await client.put(
      `/articles/${id}`,
      values
    );
    toastr.success(
      "Sukces",
      "Artykuł został zaktualizowany"
    );
    dispatch({
      type: "ARTICLE_SUCCESS",
      payload: { data },
    });
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd");
    throw error;
  }
};

export const updateArticleImage = (id, image) => async (
  dispatch
) => {
  try {
    const form_data = new FormData();
    form_data.append("image", image);

    const { data } = await client.put(
      `/articles/${id}`,
      form_data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    toastr.success(
      "Sukces",
      "Zdjęcie zostało zaktualzowane"
    );
    dispatch({
      type: "ARTICLE_SUCCESS",
      payload: { data },
    });
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd");
    throw error;
  }
};

export const deleteArticleImage = (id) => async (
  dispatch
) => {
  try {
    const { data } = await client.put(`/articles/${id}`, {
      image: null,
    });
    toastr.success("Sukces", "Zdjęcie zostało usunięte");
    dispatch({
      type: "ARTICLE_SUCCESS",
      payload: { data },
    });
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd");
    throw error;
  }
};

export const deleteArticle = async (id) => {
  try {
    await client.delete(`/articles/${id}`);
    toastr.success("Sukces", "Artykuł został usunięty");
    return id;
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd.");
    throw error;
  }
};

export const createArticleAttachment = (values) => async (
  dispatch
) => {
  try {
    const form_data = new FormData();
    Object.keys(values).forEach((key) => {
      form_data.append(key, values[key]);
    });

    const data = await client.post(
      "/article_attachments",
      form_data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    dispatch({
      type: "ARTICLE_CREATE_ATTACHMENT",
      payload: { data },
    });
    toastr.success("Sukces", "Załącznik został dodany");
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd");
    throw handleErrors(error?.response?.data?.errors);
  }
};

export const deleteArticleAttachment = (id) => async (
  dispatch
) => {
  try {
    await client.delete(`/article_attachments/${id}`);
    dispatch({
      type: "ARTICLE_DELETE_ATTACHMENT",
      payload: { id },
    });
    toastr.success("Sukces", "Załącznik został usunięty");
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd");
  }
};
