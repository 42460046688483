import React from "react";

const Button = ({ type, text, ...rest }) => {
  switch (type) {
    case "close":
      return (
        <button className="btn btn-icon" type="button" {...rest}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            className="kt-svg-icon"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g
                transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                fill="#A2A5B9"
              >
                <rect x="0" y="7" width="16" height="2" rx="1" />
                <rect
                  transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
                  x="0"
                  y="7"
                  width="16"
                  height="2"
                  rx="1"
                />
              </g>
            </g>
          </svg>
        </button>
      );
    case "add":
      return (
        <button
          {...rest}
          type="button"
          className="btn btn-lg btn-plus btn-blue"
        >
          <span>{text}</span>
        </button>
      );
    case "add-green":
      return (
        <button
          type="button"
          {...rest}
          className="btn btn-lg btn-plus btn-green"
        >
          <span>{text}</span>
        </button>
      );
    case "cancel":
      return (
        <button type="button" className="btn btn-lg btn-cancel" {...rest}>
          <span>{text ? text : "Anuluj"}</span>
        </button>
      );
    case "edit":
      return (
        <button type="button" className="btn btn-icon" {...rest}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            className="kt-svg-icon"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <rect x="0" y="0" width="24" height="24" />
              <path
                d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                fill="#91a0da"
                fillRule="nonzero"
                transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "
              />
              <path
                d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                fill="#91a0da"
                fillRule="nonzero"
                opacity="0.3"
              />
            </g>
          </svg>
        </button>
      );
    case "delete-bin":
      return (
        <button type="button" className="btn btn-icon" {...rest}>
          <svg
            id="Group_377"
            data-name="Group 377"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <rect
              id="Rectangle_378"
              data-name="Rectangle 378"
              width="24"
              height="24"
              fill="none"
            />
            <path
              id="Path_227"
              data-name="Path 227"
              d="M6,8H18l-.893,11.615A1.5,1.5,0,0,1,15.611,21H8.389a1.5,1.5,0,0,1-1.5-1.385Z"
              fill="#fd397a"
            />
            <path
              id="Path_228"
              data-name="Path 228"
              d="M14,4.5v-1a.5.5,0,0,0-.5-.5h-3a.5.5,0,0,0-.5.5v1H5.5A.5.5,0,0,0,5,5v.5a.5.5,0,0,0,.5.5h13a.5.5,0,0,0,.5-.5V5a.5.5,0,0,0-.5-.5Z"
              fill="#fd397a"
              fillRule="evenodd"
              opacity="0.3"
            />
          </svg>
        </button>
      );

    case "delete":
      return (
        <button
          type="button"
          style={{ padding: "6px 15px" }}
          className="btn btn-lg btn-pink"
          {...rest}
        >
          <svg
            id="Group_377"
            data-name="Group 377"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <rect
              id="Rectangle_378"
              data-name="Rectangle 378"
              width="24"
              height="24"
              fill="none"
            />
            <path
              id="Path_227"
              data-name="Path 227"
              d="M6,8H18l-.893,11.615A1.5,1.5,0,0,1,15.611,21H8.389a1.5,1.5,0,0,1-1.5-1.385Z"
              fill="white"
            />
            <path
              id="Path_228"
              data-name="Path 228"
              d="M14,4.5v-1a.5.5,0,0,0-.5-.5h-3a.5.5,0,0,0-.5.5v1H5.5A.5.5,0,0,0,5,5v.5a.5.5,0,0,0,.5.5h13a.5.5,0,0,0,.5-.5V5a.5.5,0,0,0-.5-.5Z"
              fill="white"
              fillRule="evenodd"
              opacity="0.3"
            />
          </svg>
          <span>{text}</span>
        </button>
      );
    case "save":
      return (
        <button type="button" className="btn btn-lg btn-green" {...rest}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="20px"
            height="20px"
            viewBox="0 0 24 24"
            version="1.1"
            className="kt-svg-icon"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <rect x="0" y="0" width="24" height="24" />
              <path
                d="M5,5 C5,4.44771525 5.44771525,4 6,4 L15.75,4 C15.9073787,4 16.0555728,4.07409708 16.15,4.2 L18.9,7.86666667 C18.9649111,7.95321475 19,8.05848156 19,8.16666667 L19,19 C19,19.5522847 18.5522847,20 18,20 L6,20 C5.44771525,20 5,19.5522847 5,19 L5,5 Z M7,6 L7,9 L9,9 L9,6 L7,6 Z M10,6 L10,9 L12,9 L12,6 L10,6 Z M13,6 L13,9 L15,9 L15,6 L13,6 Z"
                fill="white"
              />
            </g>
          </svg>
          <span>{text ? text : "Zapisz zmiany"}</span>
        </button>
      );
    case "upload-icon":
      return (
        <button className="btn btn-icon" {...rest}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <polygon points="0 0 24 0 24 24 0 24"></polygon>
              <path
                d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z"
                fill="#5D78FF"
                fillRule="nonzero"
                opacity="0.3"
              ></path>
              <path
                d="M8.95128003,13.8153448 L10.9077535,13.8153448 L10.9077535,15.8230161 C10.9077535,16.0991584 11.1316112,16.3230161 11.4077535,16.3230161 L12.4310522,16.3230161 C12.7071946,16.3230161 12.9310522,16.0991584 12.9310522,15.8230161 L12.9310522,13.8153448 L14.8875257,13.8153448 C15.1636681,13.8153448 15.3875257,13.5914871 15.3875257,13.3153448 C15.3875257,13.1970331 15.345572,13.0825545 15.2691225,12.9922598 L12.3009997,9.48659872 C12.1225648,9.27584861 11.8070681,9.24965194 11.596318,9.42808682 C11.5752308,9.44594059 11.5556598,9.46551156 11.5378061,9.48659872 L8.56968321,12.9922598 C8.39124833,13.2030099 8.417445,13.5185067 8.62819511,13.6969416 C8.71848979,13.773391 8.8329684,13.8153448 8.95128003,13.8153448 Z"
                fill="#5D78FF"
              ></path>
            </g>
          </svg>
        </button>
      );
    case "upload":
      return (
        <button className="btn btn-lg btn-blue" {...rest}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
          >
            <path fill="none" fillRule="evenodd" d="M0,0H20V20H0Z" />
            <path
              fill="#fff"
              d="M5.548,2h6.566a1.25,1.25,0,0,1,.807.3l3.97,3.358a1.25,1.25,0,0,1,.443.954V17.069c0,1.492-.017,1.6-1.548,1.6H5.548c-1.531,0-1.548-.105-1.548-1.6V3.6C4,2.105,4.017,2,5.548,2Z"
              transform="translate(-0.667 -0.333)"
              opacity="0.3"
            />
            <path
              fillRule="evenodd"
              fill="#fff"
              d="M8.868,13.064H10.5v1.673a.417.417,0,0,0,.417.417h.853a.417.417,0,0,0,.417-.417V13.064h1.63a.417.417,0,0,0,.318-.686L11.659,9.457a.417.417,0,0,0-.636,0L8.55,12.379a.417.417,0,0,0,.318.686Z"
              transform="translate(-1.409 -1.552)"
            />
          </svg>
          <span>{text ? text : "Wgraj zdjęcie"}</span>
        </button>
      );
    default:
      return (
        <button typr="button" className="btn" {...rest}>
          {text}
        </button>
      );
  }
};

export default Button;
