import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import {
  Search,
  Pagination,
  Main,
  PageLoader,
  Button,
} from "components";
import { getUsers } from "actions/users";
import moment from "moment";

const UsersList = ({
  location: { search },
  history,
  users,
  getUsers,
}) => {
  const {
    page,
    per_page,
    query: default_query,
  } = queryString.parse(search);

  const [query, setQuery] = useState(default_query || "");

  useEffect(() => {
    getUsers({ page, per_page, query });
  }, [page, per_page]);

  if (["invalid", "loading"].includes(users.status)) {
    return <PageLoader />;
  }

  if (users?.status === "failure") {
    return <p>Error</p>;
  }

  const { data, meta } = users;

  return (
    <Main
      breadcrumbs={[{ name: "Użytkownicy" }]}
      title="Użytkownicy">
      <div className="dashboard">
        <header className="dashboard__header">
          <h2 className="dashboard__title heading">
            Użytkownicy
          </h2>
          <Button
            style={{ marginLeft: 15 }}
            type="add"
            text="Dodaj"
            onClick={() => history.push("/users/new")}
          />
        </header>
        <Search
          action={(page, per_page, query) => {
            setQuery(query);
            getUsers({
              page,
              per_page,
              query,
              loading: false,
            });
          }}
          defaultValue={query}
        />
        {data?.length > 0 ? (
          <>
            <table className="table">
              <thead className="table__header table-head">
                <tr>
                  <th>Imię</th>
                  <th>Nazwisko</th>
                  <th>Adres e-mail</th>
                  <th>Konto aktywne</th>
                  <th>Data utworzenia</th>
                </tr>
              </thead>
              <tbody className="table__body table-body">
                {data.map(
                  ({
                    id,
                    first_name,
                    last_name,
                    email,
                    created_at,
                    is_active,
                  }) => (
                    <tr
                      key={id}
                      onClick={() =>
                        history.push(`/users/${id}`)
                      }>
                      <td>{first_name}</td>
                      <td>{last_name}</td>
                      <td>{email}</td>
                      <td>{is_active ? "Tak" : "Nie"}</td>
                      <td>
                        {created_at
                          ? moment(created_at).format(
                              "DD.MM.YYYY"
                            )
                          : "brak"}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </>
        ) : (
          <div style={{ padding: 20 }}>
            <p>Brak reprezentantów jednostki</p>
          </div>
        )}
        <Pagination data={{ ...meta }} />
      </div>
    </Main>
  );
};

export default connect(
  ({ users }) => ({ users }),
  (dispatch) => ({
    getUsers: (data) => dispatch(getUsers(data)),
  })
)(UsersList);
