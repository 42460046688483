import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import {
  Search,
  Pagination,
  Button,
  Main,
  PageLoader,
} from "components";

import { getArticles } from "actions/articles";
import { getArticleKind } from "utils/options/article_kind_options";

const ArticlesList = ({
  history,
  location: { search },
  articles,
  getArticles,
}) => {
  const {
    page,
    per_page,
    query: default_query,
  } = queryString.parse(search);

  const [query, setQuery] = useState(default_query || "");

  useEffect(() => {
    getArticles({ page, per_page, query });
  }, [page, per_page]);

  if (["invalid", "loading"].includes(articles.status)) {
    return <PageLoader />;
  }

  if (articles?.status === "failure") {
    return <p>Error</p>;
  }

  const { data, meta } = articles;

  return (
    <Main
      breadcrumbs={[{ name: "Artykuły" }]}
      title="Artykuły"
    >
      <div className="dashboard">
        <header className="dashboard__header">
          <h2 className="dashboard__title heading">
            Zarządzanie artykułami
          </h2>
          <Button
            type="add"
            text="Dodaj artykuł"
            onClick={() => history.push("/articles/new")}
          />
        </header>
        <Search
          action={(page, per_page, query) => {
            setQuery(query);
            getArticles({
              page,
              per_page,
              query,
              loading: false,
            });
          }}
          defaultValue={query}
        />
        {data?.length > 0 ? (
          <>
            <table className="table">
              <thead className="table__header table-head">
                <tr>
                  <th>Rodzaj</th>
                  <th>Tytuł</th>
                  <th>Slug</th>
                  <th>Data</th>
                </tr>
              </thead>
              <tbody className="table__body table-body">
                {data.map(
                  ({
                    id,
                    kind,
                    title,
                    slug,
                    date,
                  }) => (
                    <tr
                      key={id}
                      onClick={() =>
                        history.push(`/articles/${id}`)
                      }
                    >
                      <td>{getArticleKind(kind)}</td>
                      <td>{title}</td>
                      <td>{slug}</td>
                      <td>{date || "-"}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </>
        ) : (
          <div style={{ padding: 20 }}>
            <p>Brak artykułów</p>
          </div>
        )}
        <Pagination data={{ ...meta }} />
      </div>
    </Main>
  );
};

export default connect(
  ({ articles }) => ({ articles }),
  (dispatch) => ({
    getArticles: (data) => dispatch(getArticles(data)),
  })
)(ArticlesList);
