import React, { useEffect } from "react";
import { connect } from "react-redux";
import { PageLoader, Sidebar } from "components";
import { Auth, Protected } from "../routes";
import { getCurrentUser } from "actions/current_user";

const App = ({ current_user, getCurrentUser }) => {
  useEffect(() => {
    getCurrentUser();
  }, []);
  if (
    ["invalid", "loading"].includes(current_user.status)
  ) {
    return <PageLoader />;
  }

  if (current_user.status === "failure") {
    return <Auth />;
  }

  return (
    <>
      <Sidebar />
      <main className="main-container">
        <Protected />
      </main>
    </>
  );
};

export default connect(
  ({ current_user }) => ({
    current_user,
  }),
  (dispatch) => ({
    getCurrentUser: () => dispatch(getCurrentUser()),
  })
)(App);
