import React from "react";
import ReactSelect from "react-select";

const Select = ({
  label,
  options = [],
  handleChange = null,
  value,
  defaultValue,
  isDisabled = false,
  meta
}) => {
  const isError = meta && meta.touched && meta.invalid;
  const customStyles = {
    control: styles => ({
      ...styles,
      borderColor: isError ? "#ff0000" : "#E2E5EC"
    }),
    option: provided => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "13px"
    }),
    singleValue: provided => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "13px",
      padding: "4px"
    }),
    indicatorSeparator: styles => ({ ...styles, display: "none" }),
    placeholder: provided => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "13px"
    })
  };
  return (
    <div className={`form-group ${isError ? "error" : ""}`}>
      <label className="label-form">{label}</label>
      <ReactSelect
        placeholder="Wybierz..."
        styles={customStyles}
        defaultValue={defaultValue || null}
        isSearchable={true}
        noOptionsMessage={() => "Brak opcji"}
        menuShouldScrollIntoView={true}
        options={options}
        isDisabled={isDisabled}
        value={value}
        onChange={({ value }) => handleChange(value)}
        theme={theme => ({
          ...theme,
          fontFamily: "Poppins",
          borderRadius: 4,
          colors: {
            ...theme.colors,
            neutral20: "#E2E5EC"
          },
          error: meta && meta.touched && meta.invalid
        })}
      />
      {isError && meta.error && <p className="inp-error">{meta.error}</p>}
    </div>
  );
};

export default Select;
