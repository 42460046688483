import { toastr } from "react-redux-toastr";
import { client } from "utils/api";
import { removeCookie, setCookie } from "utils/cookies";

export const handleLogin = async ({ email, password }) => {
  try {
    const { auth_token } = await client.post(
      `/users/sign_in`,
      {
        email,
        password,
      }
    );

    setCookie("token", auth_token).then(() => {
      window.location.pathname = "/";
    });
  } catch (error) {
    console.log(error);
    toastr.error(
      "Błąd",
      "Wystąpił błąd podzczas logowania"
    );
  }
};

export const handleLogout = () =>
  removeCookie("token").then(
    () => (window.location.pathname = "/")
  );
