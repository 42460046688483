const initialState = {
  status: "invalid",
  data: null
};

export default (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case "PAGE_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "PAGE_SUCCESS":
      return {
        ...state,
        status: "success",
        data: payload.data,
      };
    case "PAGE_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    default:
      return state;
  }
};
