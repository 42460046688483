import React from "react";
import { withRouter } from "react-router-dom";
const Breadcrumbs = ({ data, history }) => {
  return (
    <div className="breadcrumbs__container">
      <ul className="breadcrumbs__list">
        {data.map((item, index) => {
          if (index < data.length - 1) {
            return (
              <li className="breadcrumbs__item" key={index}>
                <button
                  onClick={() =>
                    item.href && history.push(item.href)
                  }>
                  {item.name}
                </button>
              </li>
            );
          } else {
            return (
              <li className="breadcrumbs__item" key={index}>
                <span>{item.name}</span>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};

export default withRouter(Breadcrumbs);
