import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ActionBar, Main, ModalDelete, PageLoader } from "components";
import { deleteExpert, getExpert } from "actions/experts";

import BasicInfo from "./components/BasicInfo";
import Image from "./components/Image";

const EditExpert = ({
  expert,
  match: {
    params: { id },
  },
  history,
  getExpert,
}) => {
  const [is_open_delete_modal, handleOpenDeleteModal] = useState(false);

  useEffect(() => {
    getExpert(id);
  }, [id]);

  const handleDelete = async () => {
    try {
      await deleteExpert(id);
      history.push("/experts");
    } catch (error) {
      console.log(error);
    }
  };

  const { status, data } = expert;

  if (["invalid", "loading"].includes(status)) return <PageLoader />;

  if (status === "failure") {
    return <p>Error</p>;
  }

  return (
    <Main
      breadcrumbs={[{ name: "Eksperci", href: "/experts" }, { name: data?.full_name }]}
      title={data?.full_name}
    >
      {is_open_delete_modal && (
        <ModalDelete
          name={data?.title}
          handleClose={() => handleOpenDeleteModal(false)}
          handleDelete={handleDelete}
        />
      )}
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <BasicInfo />
        </div>
        <div className="column-half">
          <Image />
        </div>
      </div>
      <ActionBar
        deleteAction={() => handleOpenDeleteModal(true)}
        cancel_path="/experts"
      />
    </Main>
  );
};

export default connect(
  ({ expert }) => ({
    expert,
  }),
  (dispatch) => ({
    getExpert: (id) => dispatch(getExpert(id)),
  })
)(EditExpert);
