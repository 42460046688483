import React, { useState } from "react";
import { handleLogin } from "actions/auth";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  return (
    <div className="login-container">
      <div className="login-wrapper">
        <div className="login-panel">
          <form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              handleLogin({ email, password });
            }}>
            <div className="form-group">
              <div className="inp-wrapper">
                <label htmlFor="email">Adres E-mail</label>
                <div className="inp-group">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={email}
                    onChange={({ target: { value } }) =>
                      setEmail(value)
                    }
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="inp-wrapper">
                <label htmlFor="password">Hasło</label>
                <div className="inp-group">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    value={password}
                    onChange={({ target: { value } }) =>
                      setPassword(value)
                    }
                  />
                </div>
              </div>
            </div>
            <div className="login-submit">
              <button
                className="btn btn-lg btn-green"
                type="submit">
                <span>Zaloguj</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
