import { toastr } from "react-redux-toastr";
import { client, handleErrors } from "utils/api";

export const getCourseModule = (id) => async (dispatch) => {
  try {
    dispatch({ type: "COURSE_MODULE_LOADING" });
    const { data } = await client.get(
      `/course_modules/${id}`
    );

    dispatch({
      type: "COURSE_MODULE_SUCCESS",
      payload: { data },
    });
  } catch (error) {
    dispatch({ type: "COURSE_MODULE_FAILURE" });
    toastr.error(
      "Błąd",
      "Wystąpił błąd podzczas pobierania"
    );
  }
};

export const createCourseModule = (values) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.post(
        "/course_modules",
        values
      );
      toastr.success("Sukces", "Moduł zostal dodany");

      resolve(data);
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd");
      reject(error);
    }
  });

export const updateCourseModule = (id, values) => (
  dispatch
) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.put(
        `/course_modules/${id}`,
        values
      );
      toastr.success(
        "Sukces",
        "Moduł zostal zaktualizowany"
      );
      dispatch({
        type: "COURSE_MODULE_SUCCESS",
        payload: { data },
      });
      resolve();
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd");
      reject();
    }
  });

export const deleteCourseModule = (id) =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`/course_modules/${id}`);
      toastr.success("Sukces", "Moduł został usunięty");
      resolve(id);
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd.");
      reject(error);
    }
  });

export const createCourseModuleWidget = (values) => (
  dispatch
) =>
  new Promise(async (resolve, reject) => {
    try {
      const form_data = new FormData();

      Object.keys(values).forEach((key) => {
        form_data.append(key, values[key]);
      });
      const { data } = await client.post(
        "/course_module_widgets",
        form_data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toastr.success("Sukces", "Widget zostal dodany");
      dispatch(getCourseModule(data.course_module_id));
      resolve(data);
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd");
      reject(handleErrors(error?.response?.data?.errors));
    }
  });

export const updateCourseModuleWidget = (id, values) => (
  dispatch
) =>
  new Promise(async (resolve, reject) => {
    try {
      const form_data = new FormData();

      Object.keys(values).forEach((key) => {
        form_data.append(key, values[key]);
      });
      const { data } = await client.put(
        `/course_module_widgets/${id}`,
        form_data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toastr.success(
        "Sukces",
        "Widget zostal zaktualizowany"
      );
      dispatch(getCourseModule(data.course_module_id));
      resolve(data);
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd");
      reject(error);
    }
  });

export const deleteCourseModuleWidget = (id) => (
  dispatch
) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.delete(
        `/course_module_widgets/${id}`
      );
      toastr.success("Sukces", "Moduł został usunięty");
      resolve(id);
      dispatch(getCourseModule(data.course_module_id));
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd.");
      reject(error);
    }
  });
