import React from "react";
import Select from "react-select/async";
import debounce from "debounce-promise";

const AsyncSelect = ({
  isDisabled,
  getData,
  label,
  meta,
  onChange,
  input = {},
  change,
  initValue,
  multi = false,
}) => {
  const isError = meta && meta.touched && meta.invalid;
  const customStyles = {
    control: (styles) => ({
      ...styles,
      borderColor: isError ? "#ff0000" : "#E2E5EC",
    }),
    option: (styles, { isSelected }) => ({
      ...styles,
      fontFamily: "Poppins",
      fontSize: "13px",
      padding: "14px 30px",
      color: isSelected ? "#595D6E" : "#595D6E",
      backgroundColor: isSelected
        ? "transparent"
        : "transparent",
      transition: "0.2s",
      ":hover": {
        color: "#181C32",
        backgroundColor: "#F7F7F8",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "13px",
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      display: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "13px",
    }),
  };
  return (
    <div className={`form-group ${isError ? "error" : ""}`}>
      <div className="inp-wrapper">
        {label && <label>{label}</label>}
        <Select
          onChange={
            typeof onChange === "function"
              ? onChange
              : (item) =>
                  item?.value
                    ? change?.(input?.name, item.value)
                    : change?.(input?.name, "")
          }
          styles={customStyles}
          defaultOptions
          isClearable={true}
          cacheOptions={true}
          isMulti={multi}
          allowCreateWhileLoading
          noOptionsMessage={() => "Brak opcji"}
          isDisabled={isDisabled}
          placeholder={"Wybierz..."}
          theme={(theme) => ({
            ...theme,
            fontFamily: "Poppins",
            borderRadius: 4,
            colors: {
              ...theme.colors,
              neutral20: "#E2E5EC",
            },
            error: meta && meta.touched && meta.invalid,
          })}
          loadOptions={debounce(
            (a, b) => getData(a, b),
            500,
            {
              leading: false,
              accumulate: false,
            }
          )}
          defaultValue={initValue}
        />
        {isError && meta.error && (
          <p className="inp-error">{meta.error}</p>
        )}
      </div>
    </div>
  );
};

export default AsyncSelect;
