import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Button } from "components";
import ListItem from "./components/ListItem";

const CourseModules = ({
  history,
  match: { params },
  course_modules,
}) => {
  return (
    <div className="dashboard">
      <header className="dashboard__header">
        <h2 className="dashboard__title heading">Moduły</h2>
        <Button
          type="add"
          text="Dodaj moduł"
          onClick={() =>
            history.push(`/courses/${params.id}/modules/new`)
          }
        />
      </header>

      {course_modules?.length > 0 ? (
        <>
          <table className="table">
            <thead className="table__header table-head">
              <tr>
                <th>Tytuł</th>
                <th>Pozycja</th>
              </tr>
            </thead>
            <tbody className="table__body table-body">
              {course_modules.map((props) => (
                <ListItem key={props.id} {...props} />
              ))}
            </tbody>
          </table>
        </>
      ) : (
        <div style={{ padding: 20 }}>
          <p>Brak modułów</p>
        </div>
      )}
    </div>
  );
};

export default connect(
  ({
    course: {
      data: { course_modules },
    },
  }) => ({ course_modules })
)(withRouter(CourseModules));
