import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Button, ImageUploader } from "components";

import { ReactComponent as InfoIcon } from "icons/info.svg";
import {
  updateArticleImage,
  deleteArticleImage,
} from "actions/articles";

const Image = ({
  article,
  change,
  pristine,
  handleSubmit,
  updateArticleImage,
  deleteArticleImage,
}) => {
  const has_image = Boolean(article.image_url);

  const onSubmit = ({ image }) =>
    updateArticleImage(article.id, image);

  return (
    <div className="dashboard">
      <header className="dashboard__header">
        <div className="icon__container">
          <InfoIcon />
        </div>
        <h2 className="dashboard__title heading">
          Miniaturka
        </h2>
        {has_image ? (
          <Button
            type="delete"
            text="Usuń"
            onClick={() => deleteArticleImage(article.id)}
          />
        ) : (
          <Button
            type="save"
            disabled={pristine}
            text="Zapisz"
            onClick={handleSubmit(onSubmit)}
          />
        )}
      </header>
      <div className="dashboard__image">
        {has_image ? (
          <img src={article.image_url} alt="" />
        ) : (
          <ImageUploader
            handleFile={(file) => change("image", file)}
          />
        )}
      </div>
    </div>
  );
};

export default connect(
  ({ article }) => ({
    article: article.data,
    initialValues: {
      image: null,
    },
  }),
  (dispatch) => ({
    updateArticleImage: (id, image) =>
      dispatch(updateArticleImage(id, image)),
    deleteArticleImage: (id) =>
      dispatch(deleteArticleImage(id)),
  })
)(
  reduxForm({
    form: "article_edit_image",
    enableReinitialize: true,
  })(Image)
);
