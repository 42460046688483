import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ActionBar, Main, ModalDelete, PageLoader } from "components";
import { deleteArticle, getArticle } from "actions/articles";

import BasicInfo from "./components/BasicInfo";
import Image from "./components/Image";
import Attachments from "./components/Attachments";

const EditArticle = ({
  article,
  match: {
    params: { id },
  },
  history,
  getArticle,
}) => {
  const [is_open_delete_modal, handleOpenDeleteModal] = useState(false);

  useEffect(() => {
    getArticle(id);
  }, [id]);

  const handleDelete = async () => {
    try {
      await deleteArticle(id);
      history.push("/articles");
    } catch (error) {
      console.log(error);
    }
  };

  const { status, data } = article;

  if (["invalid", "loading"].includes(status)) return <PageLoader />;

  if (status === "failure") {
    return <p>Error</p>;
  }

  return (
    <Main
      breadcrumbs={[
        { name: "Artykuły", href: "/articles" },
        { name: data?.title },
      ]}
      title={data?.title}
    >
      {is_open_delete_modal && (
        <ModalDelete
          name={data?.title}
          handleClose={() => handleOpenDeleteModal(false)}
          handleDelete={handleDelete}
        />
      )}
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <BasicInfo />
        </div>
        <div className="column-half">
          <Image />
          {["online", "webinar", "report"].includes(data.kind) && (
            <Attachments />
          )}
        </div>
      </div>
      <ActionBar
        deleteAction={() => handleOpenDeleteModal(true)}
        cancel_path="/articles"
      />
    </Main>
  );
};

export default connect(
  ({ article }) => ({
    article,
  }),
  (dispatch) => ({
    getArticle: (id) => dispatch(getArticle(id)),
  })
)(EditArticle);
