import React from "react";
import Button from "./Button";
import { withRouter } from "react-router-dom";

const ActionBar = ({
  deleteAction,
  saveAction,
  disable_save,
  cancel_path,
  history,
}) => {
  const show_save_button = typeof saveAction === "function";
  const show_delete_button =
    typeof deleteAction === "function";
  return (
    <div className="actionbar">
      <div
        className={`actionbar__container align-${
          deleteAction ? "space" : "right"
        }`}>
        {show_delete_button ? (
          <div className="actionbar__group">
            <Button
              type="delete"
              onClick={deleteAction}
              text="Usuń"
            />
          </div>
        ) : null}
        <div className="actionbar__group">
          {cancel_path && (
            <Button
              type="cancel"
              onClick={() => history.push(cancel_path)}
            />
          )}
          {show_save_button ? (
            <Button
              type="save"
              text="Zapisz"
              disabled={disable_save}
              onClick={saveAction}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default withRouter(ActionBar);
