const initialState = {
  status: "invalid",
  data: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "EXPERT_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "EXPERT_SUCCESS":
      return {
        ...state,
        status: "success",
        data: payload.data,
      };
    case "EXPERT_FAILURE":
      return {
        ...state,
        status: "failure",
      };

    default:
      return state;
  }
};
