import React from "react";
import { withRouter } from "react-router-dom";
import SidebarItem from "./SidebarItem";
import logotype from "../../images/icons/logotype.png";

const nav = [
  {
    name: "Platforma",
    items: [
      {
        name: "Strony statyczne",
        path: "/pages",
        icon: "static",
      },
      {
        name: "Użytkownicy",
        path: "/users",
        icon: "users",
      },
      {
        name: "Artykuły",
        path: "/articles",
        icon: "list",
      },
      {
        name: "Baza wiedzy",
        path: "/courses",
        icon: "list",
      },
      {
        name: "Eksperci",
        path: "/experts",
        icon: "users",
      },
    ],
  },
];
const Sidebar = ({ history }) => {
  return (
    <aside className="sidebar">
      <div className="sidebar__logotype">
        <img src={logotype} alt="" />
      </div>
      <nav>
        {nav.map(({ name, items }) => (
          <React.Fragment key={name}>
            <p className="nav-category">{name}</p>
            <ul>
              {items.map(({ name, path, icon }, id) => (
                <SidebarItem
                  key={id}
                  name={name}
                  path={path}
                  icon={icon}
                  history={history}
                />
              ))}
            </ul>
          </React.Fragment>
        ))}
      </nav>
    </aside>
  );
};

export default withRouter(Sidebar);
