import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Button, Input } from "components";

import { ReactComponent as InfoIcon } from "icons/info.svg";
import Editor from "components/EditorSimple";
import { updateCourse } from "actions/courses";

const InputField = (props) => <Input {...props} />;

const BasicInfo = ({
  course,
  change,
  pristine,
  handleSubmit,
  updateCourse,
}) => {
  const onSubmit = (values) =>
    updateCourse(course.id, values);

  return (
    <div className="dashboard">
      <header className="dashboard__header">
        <div className="icon__container">
          <InfoIcon />
        </div>
        <h2 className="dashboard__title heading">
          Podstawowe informacje
        </h2>
        <Button
          type="save"
          disabled={pristine}
          style={{ marginLeft: "15px" }}
          text="Zapisz"
          onClick={handleSubmit(onSubmit)}
        />
      </header>
      <div className="dashboard__form">
        <form noValidate>
          <Field
            label="Tytuł"
            name="title"
            component={InputField}
          />
          <Editor
            label="Treść"
            name="description"
            change={change}
            value={course?.description}
          />
        </form>
      </div>
    </div>
  );
};

export default connect(
  ({ course }) => ({
    course: course.data,
    initialValues: {
      title: course?.data?.title,
      description: course?.data?.description,
    },
  }),
  (dispatch) => ({
    updateCourse: (id, values) =>
      dispatch(updateCourse(id, values)),
  })
)(
  reduxForm({
    form: "course_edit_basic_info",
    enableReinitialize: true,
  })(BasicInfo)
);
