import React from "react";

const Checkbox = ({
  meta,
  input,
  label,
  disabled,
  info,
  labelAside,
  mainLabel
}) => {
  return (
    <div
      className={`form-group ${meta.touched && meta.valid ? "visited" : ""} ${
        meta.touched && meta.invalid ? "error" : ""
      } ${labelAside ? "check-label-aside" : ""}`}
    >
      {labelAside && (
        <span className="check-label-aside__text">{labelAside}</span>
      )}
      {mainLabel && <span className="check-main-label">{mainLabel}</span>}
      <div className={`check-group ${disabled ? "disabled" : ""}`}>
        <input
          disabled={disabled}
          id={input.name}
          {...input}
          type="checkbox"
          defaultChecked={input.value}
        />
        <label htmlFor={input.name} className="label-form">
          {label}
        </label>
        {info && <p className="additional-info">{info}</p>}
      </div>
      {meta.touched && meta.invalid && meta.error && (
        <p className="inp-error">{meta.error}</p>
      )}
    </div>
  );
};

export default Checkbox;
