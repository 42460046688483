import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Button, ImageUploader } from "components";

import { ReactComponent as InfoIcon } from "icons/info.svg";
import {
  updateExpertImage,
  deleteExpertImage,
} from "actions/experts";

const Image = ({
  expert,
  change,
  pristine,
  handleSubmit,
  updateExpertImage,
  deleteExpertImage,
}) => {
  const has_image = Boolean(expert.image_url);

  const onSubmit = ({ image }) =>
    updateExpertImage(expert.id, image);

  return (
    <div className="dashboard">
      <header className="dashboard__header">
        <div className="icon__container">
          <InfoIcon />
        </div>
        <h2 className="dashboard__title heading">
          Miniaturka
        </h2>
        {has_image ? (
          <Button
            type="delete"
            text="Usuń"
            onClick={() => deleteExpertImage(expert.id)}
          />
        ) : (
          <Button
            type="save"
            disabled={pristine}
            text="Zapisz"
            onClick={handleSubmit(onSubmit)}
          />
        )}
      </header>
      <div className="dashboard__image">
        {has_image ? (
          <img src={expert.image_url} alt="" />
        ) : (
          <ImageUploader
            handleFile={(file) => change("image", file)}
          />
        )}
      </div>
    </div>
  );
};

export default connect(
  ({ expert }) => ({
    expert: expert.data,
    initialValues: {
      image: null,
    },
  }),
  (dispatch) => ({
    updateExpertImage: (id, image) =>
      dispatch(updateExpertImage(id, image)),
    deleteExpertImage: (id) =>
      dispatch(deleteExpertImage(id)),
  })
)(
  reduxForm({
    form: "expert_edit_image",
    enableReinitialize: true,
  })(Image)
);
