import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import {
  Search,
  Pagination,
  Button,
  Main,
  PageLoader,
} from "components";

import { getPages } from "actions/pages";

const PagesList = ({
  history,
  location: { search },
  pages,
  getPages,
}) => {
  const {
    page,
    per_page,
    query: default_query,
  } = queryString.parse(search);

  const [query, setQuery] = useState(default_query || "");

  useEffect(() => {
    getPages({ page, per_page, query });
  }, [page, per_page]);

  if (
    pages?.status === "invalid" ||
    pages?.status === "loading"
  )
    return <PageLoader />;

  if (pages?.status === "failure") {
    return <p>Error</p>;
  }

  const { data, meta } = pages;

  return (
    <Main
      breadcrumbs={[{ name: "Strony statyczne" }]}
      title="Strony statyczne">
      <div className="dashboard">
        <header className="dashboard__header">
          <h2 className="dashboard__title heading">
            Zarządzanie stronami statycznymi
          </h2>
          <Button
            type="add"
            text="Dodaj stronę statyczną"
            onClick={() => history.push("/pages/new")}
          />
        </header>
        <Search
          action={(page, per_page, query) => {
            setQuery(query);
            getPages({
              page,
              per_page,
              query,
              loading: false,
            });
          }}
          defaultValue={query}
        />
        {data?.length > 0 ? (
          <>
            <table className="table">
              <thead className="table__header table-head">
                <tr>
                  <th>Tytuł</th>
                  <th>Slug</th>
                  <th>Meta title</th>
                </tr>
              </thead>
              <tbody className="table__body table-body">
                {data.map(
                  ({ id, title, slug, meta_title }) => (
                    <tr
                      key={id}
                      onClick={() =>
                        history.push(`/pages/${id}`)
                      }>
                      <td>{title}</td>
                      <td>{slug}</td>
                      <td>{meta_title || "-"}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </>
        ) : (
          <div style={{ padding: 20 }}>
            <p>Brak stron statycznych</p>
          </div>
        )}
        <Pagination data={{ ...meta }} />
      </div>
    </Main>
  );
};

export default connect(
  ({ pages }) => ({ pages }),
  (dispatch) => ({
    getPages: (data) => dispatch(getPages(data)),
  })
)(PagesList);
