import React, { useState, useEffect, useRef } from "react";

import { ReactComponent as CloseIcon } from "icons/close.svg";

let timer;

const Modal = ({
  title,
  handleClose,
  children,
  footer
}) => {
  const [active, setActive] = useState();
  const ref = useRef();

  const handleClickOutside = (event) => {
    if (!ref?.current?.contains(event.target)) {
      handleClose();
    }
  };
  useEffect(() => {
    document.addEventListener(
      "mousedown",
      handleClickOutside
    );
    timer = setTimeout(() => {
      setActive(true);
    }, 200);
    return () => {
      clearTimeout(timer);
      document.removeEventListener(
        "mousedown",
        handleClickOutside
      );
    };
  }, []);

  return (
    <div className="popup-wrapper">
      <div
        className={`popup ${active ? "active" : ""}`}
        ref={ref}>
        <div className="popup__header">
          <h2>{title}</h2>
          <button className="button" onClick={handleClose}>
            <CloseIcon />
          </button>
        </div>
        <div className="popup__body">{children}</div>
        {footer && (
          <div className="popup__footer">{footer}</div>
        )}
      </div>
    </div>
  );
};

export default Modal;
