import React from "react";
import { withRouter } from "react-router-dom";

const ListItem = ({
  id,
  title,
  course_id,
  position,
  history,
}) => {
  return (
    <>
      <tr
        key={id}
        style={{ cursor: "pointer" }}
        onClick={() =>
          history.push(
            `/courses/${course_id}/modules/${id}`
          )
        }>
        <td>{title}</td>
        <td>{position}</td>
      </tr>
    </>
  );
};

export default withRouter(ListItem);
