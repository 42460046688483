import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import {
  NotFound,
  //
  ArticlesList,
  ArticleEdit,
  ArticleNew,
  //
  UsersList,
  UserCreate,
  UserEdit,
  //
  PagesList,
  PageEdit,
  PageNew,
  //
  CourseNew,
  CourseEdit,
  CoursesList,
  //
  CourseModuleNew,
  CourseModuleEdit,
  //
  ExpertsList,
  ExpertEdit,
  ExpertNew,
} from "pages";

const Protected = () => (
  <Switch>
    <Route exact={true} path="/" component={() => <Redirect to="/courses" />} />
    <Route exact={true} path="/articles" component={ArticlesList} />
    <Route exact={true} path="/articles/new" component={ArticleNew} />
    <Route exact={true} path="/articles/:id" component={ArticleEdit} />

    <Route exact={true} path="/users" component={UsersList} />
    <Route exact={true} path="/users/new" component={UserCreate} />
    <Route exact={true} path="/users/:id" component={UserEdit} />

    <Route exact={true} path="/pages" component={PagesList} />
    <Route exact={true} path="/pages/new" component={PageNew} />
    <Route exact={true} path="/pages/:id" component={PageEdit} />
    <Route exact={true} path="/courses" component={CoursesList} />
    <Route exact={true} path="/courses/new" component={CourseNew} />
    <Route exact={true} path="/courses/:id" component={CourseEdit} />
    <Route
      exact={true}
      path="/courses/:id/modules/new"
      component={CourseModuleNew}
    />
    <Route
      exact={true}
      path="/courses/:id/modules/:course_module_id"
      component={CourseModuleEdit}
    />
    <Route exact={true} path="/experts" component={ExpertsList} />
    <Route exact={true} path="/experts/new" component={ExpertNew} />
    <Route exact={true} path="/experts/:id" component={ExpertEdit} />
    <Route component={NotFound} />
  </Switch>
);

export default Protected;
