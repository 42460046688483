import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Button, Input } from "components";

import { ReactComponent as InfoIcon } from "icons/info.svg";
import Editor from "components/EditorSimple";
import { updateExpert } from "actions/experts";

const InputField = (props) => <Input {...props} />;

const BasicInfo = ({
  expert,
  change,
  pristine,
  handleSubmit,
  updateExpert,
}) => {
  const onSubmit = (values) => updateExpert(expert.id, values);
  return (
    <div className="dashboard">
      <header className="dashboard__header">
        <div className="icon__container">
          <InfoIcon />
        </div>
        <h2 className="dashboard__title heading">Podstawowe informacje</h2>
        <Button
          type="save"
          disabled={pristine}
          style={{ marginLeft: "15px" }}
          text="Zapisz"
          onClick={handleSubmit(onSubmit)}
        />
      </header>
      <div className="dashboard__form">
        <form noValidate>
          <Field label="Imię" name="first_name" component={InputField} />
          <Field label="Nazwisko" name="last_name" component={InputField} />
          <Editor
            label="Opis"
            name="description"
            change={change}
            value={expert?.description}
          />
          <Field
            label="Pozycja"
            name="position"
            type="number"
            component={InputField}
          />
        </form>
      </div>
    </div>
  );
};

export default connect(
  ({ expert }) => ({
    expert: expert.data,
    initialValues: {
      first_name: expert?.data?.first_name,
      last_name: expert?.data?.last_name,
      description: expert?.data?.description,
      position: expert?.data?.position,
    },
  }),
  (dispatch) => ({
    updateExpert: (id, values) => dispatch(updateExpert(id, values)),
  })
)(
  reduxForm({
    form: "expert_edit_basic_info",
    enableReinitialize: true,
  })(BasicInfo)
);
