import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  ActionBar,
  Main,
  ModalDelete,
  PageLoader,
} from "components";
import { Field, reduxForm } from "redux-form";
import { Input } from "components";
import {
  editPage,
  getPage,
  deletePage,
} from "actions/pages";

import { ReactComponent as InfoIcon } from "icons/info.svg";
import Editor from "components/EditorSimple";

const InputField = (props) => <Input {...props} />;

const required_fields = [
  "title",
  "meta_title",
  "meta_description",
];

const validate = (values) => {
  const errors = {};
  required_fields.forEach((key) => {
    if (!values[key]) {
      errors[key] = "Wymagane";
    }
  });

  return errors;
};

const EditPage = ({
  page,
  match: {
    params: { id },
  },
  change,
  pristine,
  getPage,
  editPage,
  handleSubmit,
  history,
}) => {
  const [
    is_open_delete_modal,
    handleOpenDeleteModal,
  ] = useState(false);

  useEffect(() => {
    getPage(id);
  }, [id]);

  const onSubmit = (values) => editPage(id, values);

  const handleDelete = async () => {
    try {
      await deletePage(id);
      history.push("/pages");
    } catch (error) {
      console.log(error);
    }
  };

  const { status, data } = page;

  if (status === "invalid" || status === "loading")
    return <PageLoader />;

  if (status === "failure") {
    return <p>Error</p>;
  }

  return (
    <Main
      breadcrumbs={[
        {
          name: "Strony statyczne",
          href: "/pages",
        },
        { name: data?.title },
      ]}
      title={data?.title}>
      {is_open_delete_modal && (
        <ModalDelete
          name={data?.title}
          handleClose={() => handleOpenDeleteModal(false)}
          handleDelete={handleDelete}
        />
      )}
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <div className="dashboard">
            <header className="dashboard__header">
              <div className="icon__container">
                <InfoIcon />
              </div>
              <h2 className="dashboard__title heading">
                Podstawowe informacje
              </h2>
            </header>
            <div className="dashboard__form">
              <form noValidate>
                <Field
                  label="Tytuł"
                  name="title"
                  component={InputField}
                />
                <Field
                  label="Meta title"
                  name="meta_title"
                  component={InputField}
                />
                <Field
                  label="Meta description"
                  name="meta_description"
                  component={InputField}
                />
                <Editor
                  label="Treść"
                  name="content"
                  change={change}
                  value={page?.data?.content}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
      <ActionBar
        deleteAction={() => handleOpenDeleteModal(true)}
        saveAction={handleSubmit(onSubmit)}
        disable_save={pristine}
        cancel_path="/pages"
      />
    </Main>
  );
};

export default connect(
  ({ page }) => ({
    page,
    initialValues: {
      title: page?.data?.title,
      meta_title: page?.data?.meta_title,
      meta_description: page?.data?.meta_description,
      content: page?.data?.content,
    },
  }),
  (dispatch) => ({
    editPage: (data, id) => dispatch(editPage(data, id)),
    getPage: (id) => dispatch(getPage(id)),
  })
)(
  reduxForm({
    form: "page_edit",
    enableReinitialize: true,
    validate,
  })(EditPage)
);
