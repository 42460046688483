import axios from "axios";

const url =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : "https://api.integracja.fwmp.pl";

export const api_url = `${url}/v1`;

const admin_url = `${url}/v1/admin`;

export const client = axios.create({ baseURL: admin_url });

client.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const authorizeApi = (token) => {
  client.defaults.headers.common["Authorization"] = token;
};

export const handleErrors = (data) => {
  try {
    const errors = {};

    data.map(
      ({ detail, source: { pointer } }) =>
        (errors[
          pointer.split("/")[pointer.split("/").length - 1]
        ] = detail)
    );
    return errors;
  } catch (error) {
    console.log(error);
    return [];
  }
};
