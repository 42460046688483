import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import React from "react";
import DatePickerModule from "react-datetime-picker";

const DatePicker = ({
  value,
  name,
  onChange,
  label,
  disabled = false,
}) => {
  return (
    <div className="form-group">
      <div className="inp-wrapper">
        {label && (
          <label htmlFor={name} className="form-label">
            {label}
          </label>
        )}
        <div className="calendar-group">
          <DatePickerModule
            disabled={disabled}
            calendarIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19.36"
                height="19.36"
                viewBox="0 0 19.36 19.36"
              >
                <path
                  fill="#a2a5b9"
                  d="M17.092,1.513h-.908V0H14.672V1.513H4.689V0H3.176V1.513H2.269A2.271,2.271,0,0,0,0,3.781v13.31A2.271,2.271,0,0,0,2.269,19.36H17.092a2.271,2.271,0,0,0,2.269-2.269V3.781A2.271,2.271,0,0,0,17.092,1.513Zm.756,15.579a.757.757,0,0,1-.756.756H2.269a.757.757,0,0,1-.756-.756V7.109H17.848Zm0-11.5H1.513V3.781a.757.757,0,0,1,.756-.756h.908V4.538H4.689V3.025h9.983V4.538h1.513V3.025h.908a.757.757,0,0,1,.756.756Z"
                />
                <g transform="translate(2.473 8.399)">
                  <g transform="translate(0 0)">
                    <rect
                      fill="#a2a5b9"
                      width="1"
                      height="1"
                      transform="translate(0.196 0.27)"
                    />
                  </g>
                </g>
                <g transform="translate(5.436 8.399)">
                  <g transform="translate(0 0)">
                    <rect
                      fill="#a2a5b9"
                      width="1"
                      height="1"
                      transform="translate(0.233 0.27)"
                    />
                  </g>
                </g>
                <g transform="translate(8.399 8.399)">
                  <g transform="translate(0 0)">
                    <rect
                      fill="#a2a5b9"
                      width="1"
                      height="1"
                      transform="translate(0.27 0.27)"
                    />
                  </g>
                </g>
                <g transform="translate(11.362 8.399)">
                  <g transform="translate(0 0)">
                    <rect
                      fill="#a2a5b9"
                      width="1"
                      height="1"
                      transform="translate(0.307 0.27)"
                    />
                  </g>
                </g>
                <g transform="translate(14.325 8.399)">
                  <g transform="translate(0 0)">
                    <rect
                      fill="#a2a5b9"
                      width="1"
                      height="1"
                      transform="translate(0.344 0.27)"
                    />
                  </g>
                </g>
                <g transform="translate(2.473 11.362)">
                  <g transform="translate(0 0)">
                    <rect
                      fill="#a2a5b9"
                      width="1"
                      height="1"
                      transform="translate(0.196 0.307)"
                    />
                  </g>
                </g>
                <g transform="translate(5.436 11.362)">
                  <g transform="translate(0 0)">
                    <rect
                      fill="#a2a5b9"
                      width="1"
                      height="1"
                      transform="translate(0.233 0.307)"
                    />
                  </g>
                </g>
                <g transform="translate(8.399 11.362)">
                  <g transform="translate(0 0)">
                    <rect
                      fill="#a2a5b9"
                      width="1"
                      height="1"
                      transform="translate(0.27 0.307)"
                    />
                  </g>
                </g>
                <g transform="translate(11.362 11.362)">
                  <g transform="translate(0 0)">
                    <rect
                      fill="#a2a5b9"
                      width="1"
                      height="1"
                      transform="translate(0.307 0.307)"
                    />
                  </g>
                </g>
                <g transform="translate(2.473 14.325)">
                  <g transform="translate(0 0)">
                    <rect
                      fill="#a2a5b9"
                      width="1"
                      height="1"
                      transform="translate(0.196 0.344)"
                    />
                  </g>
                </g>
                <g transform="translate(5.436 14.325)">
                  <g transform="translate(0 0)">
                    <rect
                      fill="#a2a5b9"
                      width="1"
                      height="1"
                      transform="translate(0.233 0.344)"
                    />
                  </g>
                </g>
                <g transform="translate(8.399 14.325)">
                  <g transform="translate(0 0)">
                    <rect
                      fill="#a2a5b9"
                      width="1"
                      height="1"
                      transform="translate(0.27 0.344)"
                    />
                  </g>
                </g>
                <g transform="translate(11.362 14.325)">
                  <g transform="translate(0 0)">
                    <rect
                      fill="#a2a5b9"
                      width="1"
                      height="1"
                      transform="translate(0.307 0.344)"
                    />
                  </g>
                </g>
                <g transform="translate(14.325 11.362)">
                  <g transform="translate(0 0)">
                    <rect
                      fill="#a2a5b9"
                      width="1"
                      height="1"
                      transform="translate(0.344 0.307)"
                    />
                  </g>
                </g>
              </svg>
            }
            onChange={(val) => onChange(val)}
            value={value}
            showLeadingZeros={false}
            locale="pl-PL"
            format="y-MM-dd"
          />
        </div>
      </div>
    </div>
  );
};

export default DatePicker;
