import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Main, Input, ActionBar, ImageUploader } from "components";
import { createExpert } from "actions/experts";

import { ReactComponent as InfoIcon } from "icons/info.svg";
import Editor from "components/EditorSimple";

const InputField = (props) => <Input {...props} />;

const required_fields = ["first_name", "last_name"];

const validate = (values) => {
  const errors = {};
  required_fields.forEach((key) => {
    if (!values[key]) {
      errors[key] = "Wymagane";
    }
  });

  return errors;
};

const CreateExpert = ({ handleSubmit, history, change }) => {
  const onSubmit = async (values) => {
    try {
      const id = await createExpert(values);
      history.push(`/experts/${id}`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Main
      breadcrumbs={[
        {
          name: "Eksperci",
          href: "/experts",
        },
        { name: "Dodaj eksperta" },
      ]}
      title="Dodaj eksperta"
    >
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <div className="dashboard">
            <header className="dashboard__header">
              <div className="icon__container">
                <InfoIcon />
              </div>
              <h2 className="dashboard__title heading">
                Podstawowe informacje
              </h2>
            </header>
            <div className="dashboard__form">
              <form noValidate>
                <Field label="Imię" name="first_name" component={InputField} />
                <Field
                  label="Nazwisko"
                  name="last_name"
                  component={InputField}
                />
                <Editor
                  label="Opis"
                  name="description"
                  change={change}
                  value=""
                />
                <Field
                  label="Pozycja"
                  name="position"
                  type="number"
                  component={InputField}
                />
              </form>
            </div>
          </div>
        </div>
        <div className="column-half">
          <div className="dashboard">
            <header className="dashboard__header">
              <div className="icon__container">
                <InfoIcon />
              </div>
              <h2 className="dashboard__title heading">Zdjęcie</h2>
            </header>
            <div className="dashboard__form">
              <ImageUploader handleFile={(file) => change("image", file)} />
            </div>
          </div>
        </div>
      </div>
      <ActionBar saveAction={handleSubmit(onSubmit)} cancel_path="/experts" />
    </Main>
  );
};

export default connect(() => ({
  initialValues: {
    first_name: "",
    last_name: "",
    position: 1,
  },
}))(
  reduxForm({
    form: "create_expert",
    enableReinitialize: true,
    validate,
  })(CreateExpert)
);
