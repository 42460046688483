import { toastr } from "react-redux-toastr";
import { client, handleErrors } from "utils/api";

export const getExperts =
  ({ page = 1, per_page = 10, loading = true, query }) =>
  async (dispatch) => {
    try {
      if (loading) {
        dispatch({ type: "EXPERTS_LOADING" });
      }

      const { data, meta } = await client.get(
        `/experts?page=${page}&per_page=${per_page}${
          query ? `&search=${query}` : ""
        }`
      );
      dispatch({
        type: "EXPERTS_SUCCESS",
        payload: { data, meta },
      });
    } catch (error) {
      dispatch({ type: "EXPERTS_FAILURE" });
      toastr.error("Błąd", "Wystąpił błąd podzczas pobierania");
    }
  };

export const getExpert = (id) => async (dispatch) => {
  try {
    dispatch({ type: "EXPERT_LOADING" });
    const { data } = await client.get(`/experts/${id}`);

    dispatch({
      type: "EXPERT_SUCCESS",
      payload: { data },
    });
  } catch (error) {
    dispatch({ type: "EXPERT_FAILURE" });
    toastr.error("Błąd", "Wystąpił błąd podzczas pobierania");
  }
};

export const createExpert = async (values) => {
  try {
    const form_data = new FormData();
    Object.keys(values).forEach((key) => {
      form_data.append(key, values[key]);
    });

    const { id } = await client.post("/experts", form_data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    toastr.success("Sukces", "Artykuł został utworzony");
    return id;
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd");
    throw handleErrors(error?.response?.data?.errors);
  }
};

export const updateExpert = (id, values) => async (dispatch) => {
  try {
    const { data } = await client.put(`/experts/${id}`, values);
    toastr.success("Sukces", "Artykuł został zaktualizowany");
    dispatch({
      type: "EXPERT_SUCCESS",
      payload: { data },
    });
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd");
    throw error;
  }
};

export const updateExpertImage = (id, image) => async (dispatch) => {
  try {
    const form_data = new FormData();
    form_data.append("image", image);

    const { data } = await client.put(`/experts/${id}`, form_data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    toastr.success("Sukces", "Zdjęcie zostało zaktualzowane");
    dispatch({
      type: "EXPERT_SUCCESS",
      payload: { data },
    });
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd");
    throw error;
  }
};

export const deleteExpertImage = (id) => async (dispatch) => {
  try {
    const { data } = await client.put(`/experts/${id}`, {
      image: null,
    });
    toastr.success("Sukces", "Zdjęcie zostało usunięte");
    dispatch({
      type: "EXPERT_SUCCESS",
      payload: { data },
    });
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd");
    throw error;
  }
};

export const deleteExpert = async (id) => {
  try {
    await client.delete(`/experts/${id}`);
    toastr.success("Sukces", "Artykuł został usunięty");
    return id;
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd.");
    throw error;
  }
};
