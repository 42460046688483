import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  ActionBar,
  Main,
  ModalDelete,
  PageLoader,
} from "components";
import { deleteCourse, getCourse } from "actions/courses";

import BasicInfo from "./components/BasicInfo";
import Image from "./components/Image";
import CourseModules from "./components/CourseModules";

const EditCourse = ({
  course,
  match: {
    params: { id },
  },
  history,
  getCourse,
}) => {
  const [
    is_open_delete_modal,
    handleOpenDeleteModal,
  ] = useState(false);

  useEffect(() => {
    getCourse(id);
  }, [id]);

  const handleDelete = async () => {
    try {
      await deleteCourse(id);
      history.push("/courses");
    } catch (error) {
      console.log(error);
    }
  };

  const { status, data } = course;

  if (status === "invalid" || status === "loading")
    return <PageLoader />;

  if (status === "failure") {
    return <p>Error</p>;
  }

  return (
    <Main
      breadcrumbs={[
        { name: "Kursy", href: "/courses" },
        { name: data?.title },
      ]}
      title={data?.title}>
      {is_open_delete_modal && (
        <ModalDelete
          name={data?.title}
          handleClose={() => handleOpenDeleteModal(false)}
          handleDelete={handleDelete}
        />
      )}
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <BasicInfo />
          <Image />
        </div>
        <div className="column-half">
          <CourseModules />
        </div>
      </div>
      <ActionBar
        deleteAction={() => handleOpenDeleteModal(true)}
        cancel_path="/courses"
      />
    </Main>
  );
};

export default connect(
  ({ course }) => ({
    course,
  }),
  (dispatch) => ({
    getCourse: (id) => dispatch(getCourse(id)),
  })
)(EditCourse);
