import React from "react";
import {
  Button,
  ImageUploader,
  Input,
  Modal,
  Select,
} from "components";
import { connect } from "react-redux";
import {
  Field,
  formValueSelector,
  reduxForm,
  SubmissionError,
} from "redux-form";
import { updateCourseModuleWidget } from "actions/course_module";
import course_module_widget_options from "utils/options/course_module_widget_options";
import Editor from "components/EditorSimple";

const selector = formValueSelector(
  "update_course_module_widget"
);

const InputField = (props) => <Input {...props} />;
const SelectField = (props) => (
  <Select isDisabled {...props} />
);

const UpdateWidget = ({
  id,
  type,
  video_url,
  image_url,
  handleClose,
  change,
  pristine,
  handleSubmit,
  updateCourseModuleWidget,
  initialValues,
}) => {
  const onSubmit = (values) =>
    updateCourseModuleWidget(id, values).catch((error) => {
      throw new SubmissionError(error);
    });

  return (
    <>
      <Modal
        handleClose={handleClose}
        title="Edytuj widget"
        footer={
          <>
            <Button type="cancel" onClick={handleClose} />
            <Button
              type="save"
              text="Potwierdź"
              disabled={pristine}
              onClick={handleSubmit(onSubmit)}
            />
          </>
        }>
        <form noValidate>
          <Field
            label="Pozycja"
            name="position"
            type="number"
            component={InputField}
          />
          <Field
            label="Typ"
            name="type"
            component={SelectField}
            options={course_module_widget_options}
            handleChange={(value) => change("type", value)}
            defaultValue={course_module_widget_options.find(
              ({ value }) => type === value
            )}
          />
          {type === "CourseModuleWidget::Text" && (
            <Editor
              name="text"
              change={change}
              value={initialValues.text}
            />
          )}
          {type === "CourseModuleWidget::Video" && (
            <>
              {video_url && (
                <video width="100%" controls>
                  <source
                    src={video_url}
                    type="video/mp4"
                  />
                </video>
              )}
              <ImageUploader
                accept="video/mp4,video/x-m4v,video/*"
                handleFile={(file) => change("video", file)}
              />
            </>
          )}
          {type === "CourseModuleWidget::File" && (
            <>
              <ImageUploader
                accept="application/pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword"
                handleFile={(file) => change("file", file)}
              />
            </>
          )}
          {type === "CourseModuleWidget::Image" && (
            <>
              <img
                src={image_url}
                alt=""
                style={{ maxWidth: "100%" }}
              />
              <ImageUploader
                accept="image/*"
                handleFile={(file) => change("image", file)}
              />
            </>
          )}
          {type === "CourseModuleWidget::Youtube" && (
            <Field
              label="Youtube ID"
              name="youtube_id"
              component={InputField}
            />
          )}
        </form>
      </Modal>
    </>
  );
};

export default connect(
  (state) => ({
    form_values: {
      type: selector(state, "type"),
    },
  }),
  (dispatch) => ({
    updateCourseModuleWidget: (id, values) =>
      dispatch(updateCourseModuleWidget(id, values)),
  })
)(
  reduxForm({
    form: "update_course_module_widget",
    enableReinitialize: true,
  })(UpdateWidget)
);
