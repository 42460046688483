import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import { reducer as toastr } from "react-redux-toastr";
import current_user from "./current_user";

import users from "./users";
import user from "./user";

import pages from "./pages";
import page from "./page";

import articles from "./articles";
import article from "./article";

import experts from "./experts";
import expert from "./expert";

import courses from "./courses";
import course from "./course";

import course_module from "./course_module";

export default combineReducers({
  form,
  articles,
  article,
  experts,
  expert,
  toastr,
  current_user,
  users,
  user,
  courses,
  course,
  course_module,
  pages,
  page,
});
