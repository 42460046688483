import React from "react";
import { Field, reduxForm } from "redux-form";
import { Main, Input, ActionBar } from "components";
import { createCourseModule } from "actions/course_module";

import { ReactComponent as InfoIcon } from "icons/info.svg";

const InputField = (props) => <Input {...props} />;

const validate = (values) => {
  const errors = {};
  ["title"].forEach((key) => {
    if (!values[key]) {
      errors[key] = "Wymagane";
    }
  });

  return errors;
};

const CourseModuleNew = ({
  handleSubmit,
  history,
  pristine,
  match: { params },
}) => {
  const onSubmit = async (values) => {
    try {
      const { id } = await createCourseModule({
        course_id: params.id,
        ...values,
      });
      history.push(`/courses/${params.id}/modules/${id}`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Main
      breadcrumbs={[
        { name: "Kurs", href: `/courses/${params.id}` },
        { name: "Dodaj moduł" },
      ]}
      title="Dodaj moduł">
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <div className="dashboard">
            <header className="dashboard__header">
              <div className="icon__container">
                <InfoIcon />
              </div>
              <h2 className="dashboard__title heading">
                Podstawowe informacje
              </h2>
            </header>
            <div className="dashboard__form">
              <form noValidate>
                <Field
                  label="Tytuł"
                  name="title"
                  component={InputField}
                />
                <Field
                  label="Pozycja"
                  name="position"
                  type="number"
                  component={InputField}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
      <ActionBar
        saveAction={handleSubmit(onSubmit)}
        disable_save={pristine}
        cancel_path={`/courses/${params.id}`}
      />
    </Main>
  );
};

export default reduxForm({
  form: "create_course_module",
  enableReinitialize: true,
  validate,
})(CourseModuleNew);
