import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Button, ModalDelete } from "components";
import { deleteArticleAttachment } from "actions/articles";

import CreateAttachment from "./CreateAttachment";
import { saveAs } from "file-saver";

const Attachments = ({
  article_attachments,
  deleteArticleAttachment,
}) => {
  const [
    is_open_create_attachment_modal,
    handleOpenCreateAttachmentModal,
  ] = useState(false);

  const [delete_id, setDeleteId] = useState(null);

  const handleDelete = async (id) => {
    try {
      await deleteArticleAttachment(id);
      setDeleteId(null);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {delete_id && (
        <ModalDelete
          name="załącznik"
          handleClose={() => setDeleteId(null)}
          handleDelete={() => handleDelete(delete_id)}
        />
      )}
      <div className="dashboard">
        <header className="dashboard__header">
          <h2 className="dashboard__title heading">
            Załączniki
          </h2>
          <Button
            type="add"
            text="Dodaj plik"
            onClick={() =>
              handleOpenCreateAttachmentModal(true)
            }
          />
        </header>
        {is_open_create_attachment_modal && (
          <CreateAttachment
            handleClose={() =>
              handleOpenCreateAttachmentModal(false)
            }
          />
        )}

        {article_attachments?.length > 0 ? (
          <>
            <table className="table">
              <thead className="table__header table-head">
                <tr>
                  <th>Nazwa pliku</th>
                  <th width="50" />
                </tr>
              </thead>
              <tbody className="table__body table-body">
                {article_attachments.map(
                  ({ id, file_name, file_url }) => (
                    <tr
                      key={id}
                      onClick={() =>
                        saveAs(file_url, file_name)
                      }
                    >
                      <td>{file_name}</td>
                      <td>
                        <Button
                          type="delete-bin"
                          onClick={(e) => {
                            e.stopPropagation();
                            setDeleteId(id);
                          }}
                        />
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </>
        ) : null}
      </div>
    </>
  );
};

export default connect(
  ({
    article: {
      data: { article_attachments },
    },
  }) => ({ article_attachments }),
  (dispatch) => ({
    deleteArticleAttachment: (id) =>
      dispatch(deleteArticleAttachment(id)),
  })
)(withRouter(Attachments));
