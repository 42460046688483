import { toastr } from "react-redux-toastr";
import { client } from "utils/api";

export const getPages = ({
  page = 1,
  per_page = 10,
  loading = true,
  query,
}) => async (dispatch) => {
  try {
    loading && dispatch({ type: "PAGES_LOADING" });

    const { data, meta } = await client.get(
      `/pages?page=${page}&per_page=${per_page}${
        query ? `&search=${query}` : ""
      }`
    );
    dispatch({
      type: "PAGES_SUCCESS",
      payload: { data, meta },
    });
  } catch (error) {
    dispatch({ type: "PAGES_FAILURE" });
    toastr.error(
      "Błąd",
      "Wystąpił błąd podzczas pobierania"
    );
  }
};

export const getPage = (id) => async (dispatch) => {
  try {
    dispatch({ type: "PAGE_LOADING" });
    const data = await client.get(`/pages/${id}`);

    dispatch({
      type: "PAGE_SUCCESS",
      payload: { data },
    });
  } catch (error) {
    dispatch({ type: "PAGE_FAILURE" });
    toastr.error(
      "Błąd",
      "Wystąpił błąd podzczas pobierania"
    );
  }
};

export const addPage = (values) =>
  new Promise(async (resolve, reject) => {
    try {
      const data = await client.post(`/pages`, values);
      toastr.success("Sukces", "Strona została utworzona");
      resolve(data);
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd");
      reject();
    }
  });

export const editPage = (id, values) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      const data = await client.put(`/pages/${id}`, values);
      toastr.success(
        "Sukces",
        "Strona została zaktualizowana"
      );
      dispatch({
        type: "PAGE_SUCCESS",
        payload: { data },
      });
      resolve();
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd");
      reject();
    }
  });

export const deletePage = (id) =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`/pages/${id}`);
      toastr.success("Sukces", "Strona została usunięta");
      resolve(id);
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd.");
      reject(error);
    }
  });
