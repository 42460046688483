import { Cookies } from 'react-cookie'

const cookies = new Cookies()

export const getCookie = name => cookies.get(name)

export const removeCookie = name =>
  new Promise((resolve, reject) => {
    cookies.remove(name, { path: '/' })
    if (!!!getCookie(name)) resolve()
  })

export const setCookie = (name, value) =>
  new Promise(resolve => {
    cookies.set(name, value, { path: '/' })
    resolve()
  })
