import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Button, ModalDelete } from "components";
import course_module_widget_options from "utils/options/course_module_widget_options";
import { deleteCourseModuleWidget } from "actions/course_module";

import CreateWidget from "./components/CreateWidget";
import UpdateWidget from "./components/UpdateWidget";

const getTypeValue = ({
  type,
  youtube_id,
  text,
  video_file_name,
  file_name,
  image_file_name,
}) => {
  switch (type) {
    case "CourseModuleWidget::Text":
      return text
        ? text.length > 100
          ? `${text.slice(0, 100)}...`
          : text
        : "-";
    case "CourseModuleWidget::Youtube":
      return youtube_id;
    case "CourseModuleWidget::Video":
      return video_file_name;
    case "CourseModuleWidget::File":
      return file_name;
    case "CourseModuleWidget::Image":
      return image_file_name;
    default:
      return "-";
  }
};

const CourseModuleWidgets = ({
  course_module_widgets,
  deleteCourseModuleWidget,
}) => {
  const [
    is_open_create_widget_modal,
    handleOpenCreateWidgetModal,
  ] = useState(false);

  const [update_data, setUpdateData] = useState(false);
  const [delete_id, setDeleteId] = useState(null);

  const handleDelete = async (id) => {
    try {
      await deleteCourseModuleWidget(id);
      setDeleteId(null);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {delete_id && (
        <ModalDelete
          name="widget"
          handleClose={() => setDeleteId(null)}
          handleDelete={() => handleDelete(delete_id)}
        />
      )}
      <div className="dashboard">
        <header className="dashboard__header">
          <h2 className="dashboard__title heading">
            Widgety
          </h2>
          <Button
            type="add"
            text="Dodaj widget"
            onClick={() =>
              handleOpenCreateWidgetModal(true)
            }
          />
        </header>
        {is_open_create_widget_modal && (
          <CreateWidget
            handleClose={() =>
              handleOpenCreateWidgetModal(false)
            }
          />
        )}

        {update_data?.id && (
          <UpdateWidget
            handleClose={() => setUpdateData(null)}
            {...update_data}
          />
        )}
        {course_module_widgets?.length > 0 ? (
          <>
            <table className="table">
              <thead className="table__header table-head">
                <tr>
                  <th width="200">Typ</th>
                  <th width="100">Pozycja</th>
                  <th>Wartość</th>
                  <th width="50" />
                </tr>
              </thead>
              <tbody className="table__body table-body">
                {course_module_widgets.map(
                  ({
                    id,
                    position,
                    type,
                    youtube_id,
                    text,
                    video_url,
                    video_file_name,
                    file_name,
                    image_file_name,
                    image_url,
                  }) => (
                    <tr
                      key={id}
                      onClick={() =>
                        setUpdateData({
                          id,
                          type,
                          video_url,
                          image_url,
                          initialValues: {
                            position,
                            text,
                            youtube_id,
                          },
                        })
                      }>
                      <td>
                        {
                          course_module_widget_options.find(
                            ({ value }) => value === type
                          )?.label
                        }
                      </td>
                      <td>{position}</td>
                      <td>
                        {getTypeValue({
                          type,
                          youtube_id,
                          text,
                          video_file_name,
                          file_name,
                          image_file_name
                        })}
                      </td>
                      <td>
                        <Button
                          type="delete-bin"
                          onClick={(e) => {
                            e.stopPropagation();
                            setDeleteId(id);
                          }}
                        />
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </>
        ) : null}
      </div>
    </>
  );
};

export default connect(
  ({
    course_module: {
      data: { course_module_widgets },
    },
  }) => ({ course_module_widgets }),
  (dispatch) => ({
    deleteCourseModuleWidget: (id) =>
      dispatch(deleteCourseModuleWidget(id)),
  })
)(withRouter(CourseModuleWidgets));