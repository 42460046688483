import React from "react";
import { Button, ImageUploader, Modal } from "components";
import { connect } from "react-redux";
import { reduxForm, SubmissionError } from "redux-form";
import { createArticleAttachment } from "actions/articles";

const CreateAttachment = ({
  handleClose,
  change,
  pristine,
  handleSubmit,
  createArticleAttachment,
}) => {
  const onSubmit = (values) =>
    createArticleAttachment(values)
      .then(() => handleClose())
      .catch((error) => {
        throw new SubmissionError(error);
      });

  return (
    <Modal
      handleClose={handleClose}
      title="Dodaj plik"
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <Button
            type="save"
            text="Potwierdź"
            disabled={pristine}
            onClick={handleSubmit(onSubmit)}
          />
        </>
      }
    >
      <form noValidate>
        <ImageUploader
          accept="*"
          handleFile={(file) => change("file", file)}
        />
      </form>
    </Modal>
  );
};

export default connect(
  (state) => ({
    initialValues: {
      article_id: state.article.data.id,
    },
  }),
  (dispatch) => ({
    createArticleAttachment: (values) =>
      dispatch(createArticleAttachment(values)),
  })
)(
  reduxForm({
    form: "create_article_attachment",
    enableReinitialize: true,
  })(CreateAttachment)
);
