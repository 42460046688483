import React from "react";
import { ReactComponent as DashboardIcon } from "./dashboard.svg";
import { ReactComponent as InfoIcon } from "./info.svg";
import { ReactComponent as ListIcon } from "./list.svg";
import { ReactComponent as UsersIcon } from "./user.svg";
import { ReactComponent as StaticPagesIcon } from "./page.svg";

export const renderIcons = (icon) => {
  switch (icon) {
    case "users":
      return <UsersIcon />;
    case "dashboard":
      return <DashboardIcon />;
    case "info":
      return <InfoIcon />;
    case "list":
      return <ListIcon />;
    case "static":
      return <StaticPagesIcon />;

    default:
      return null;
  }
};
