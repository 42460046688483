import { toastr } from "react-redux-toastr";
import { client, handleErrors } from "utils/api";

export const getCourses = ({
  page = 1,
  per_page = 10,
  loading = true,
  query,
}) => async (dispatch) => {
  try {
    if (loading) {
      dispatch({ type: "COURSES_LOADING" });
    }

    const { data, meta } = await client.get(
      `/courses?page=${page}&per_page=${per_page}${
        query ? `&search=${query}` : ""
      }`
    );
    dispatch({
      type: "COURSES_SUCCESS",
      payload: { data, meta },
    });
  } catch (error) {
    dispatch({ type: "COURSES_FAILURE" });
    toastr.error(
      "Błąd",
      "Wystąpił błąd podzczas pobierania"
    );
  }
};

export const getCourse = (id) => async (dispatch) => {
  try {
    dispatch({ type: "COURSE_LOADING" });
    const { data } = await client.get(`/courses/${id}`);

    dispatch({
      type: "COURSE_SUCCESS",
      payload: { data },
    });
  } catch (error) {
    dispatch({ type: "COURSE_FAILURE" });
    toastr.error(
      "Błąd",
      "Wystąpił błąd podzczas pobierania"
    );
  }
};

export const createCourse = (values) =>
  new Promise(async (resolve, reject) => {
    try {
      const form_data = new FormData();
      Object.keys(values).forEach((key) => {
        form_data.append(key, values[key]);
      });

      const { data } = await client.post(
        `/courses`,
        form_data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toastr.success("Sukces", "Kurs został utworzony");
      resolve(data);
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd");
      reject(handleErrors(error?.response?.data?.errors));
    }
  });

export const updateCourse = (id, values) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.put(
        `/courses/${id}`,
        values
      );
      toastr.success(
        "Sukces",
        "Kurs został zaktualizowany"
      );
      dispatch({
        type: "COURSE_SUCCESS",
        payload: { data },
      });
      resolve();
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd");
      reject();
    }
  });

export const updateCourseImage = (id, image) => (
  dispatch
) =>
  new Promise(async (resolve, reject) => {
    try {
      const form_data = new FormData();
      form_data.append("image", image);

      const { data } = await client.put(
        `/courses/${id}`,
        form_data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toastr.success(
        "Sukces",
        "Kurs został zaktualizowany"
      );
      dispatch({
        type: "COURSE_SUCCESS",
        payload: { data },
      });
      resolve();
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd");
      reject();
    }
  });

export const deleteCourseImage = (id) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.put(`/courses/${id}`, {
        image: null,
      });
      toastr.success(
        "Sukces",
        "Kurs został zaktualizowany"
      );
      dispatch({
        type: "COURSE_SUCCESS",
        payload: { data },
      });
      resolve(id);
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd");
      reject(error);
    }
  });

export const deleteCourse = (id) =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`/courses/${id}`);
      toastr.success("Sukces", "Kurs został usunięty");
      resolve(id);
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd.");
      reject(error);
    }
  });
