import React from "react";
import Topbar from "./components/Topbar";
import Breadcrumbs from "./components/Breadcrumbs";

const Main = ({ children, breadcrumbs, title }) => {
  return (
    <div className="main-dashboard">
      <Breadcrumbs data={breadcrumbs} />
      <Topbar title={title} />
      <div className="main-dashboard__container">
        {children}
      </div>
    </div>
  );
};

export default Main;
