const initialState = {
  status: "invalid",
  data: null,
};

export default (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case "ARTICLE_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "ARTICLE_SUCCESS":
      return {
        ...state,
        status: "success",
        data: payload.data,
      };
    case "ARTICLE_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    case "ARTICLE_CREATE_ATTACHMENT": {
      return {
        ...state,
        data: {
          ...state.data,
          article_attachments: [
            ...state.data.article_attachments,
            payload.data,
          ],
        },
      };
    }
    case "ARTICLE_DELETE_ATTACHMENT": {
      return {
        ...state,
        data: {
          ...state.data,
          article_attachments: state.data.article_attachments.filter(
            ({ id }) => id !== payload.id
          ),
        },
      };
    }
    default:
      return state;
  }
};
