const article_kind_options = [
  { label: "Aktualności", value: "article" },
  { label: "Szkolenia on-line", value: "online" },
  {
    label: "Webinaria",
    value: "webinar",
  },
  {
    label: "Raport",
    value: "report",
  },
  {
    label: "Konferencje",
    value: "conferences",
  },
];

export const getArticleKind = (kind) =>
  article_kind_options.find(({ value }) => value === kind)
    ?.label || kind;

export default article_kind_options;
