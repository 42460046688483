import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import { Search, Pagination, Button, Main, PageLoader } from "components";

import { getExperts } from "actions/experts";

const ExpertsList = ({
  history,
  location: { search },
  experts,
  getExperts,
}) => {
  const { page, per_page, query: default_query } = queryString.parse(search);

  const [query, setQuery] = useState(default_query || "");

  useEffect(() => {
    getExperts({ page, per_page, query });
  }, [page, per_page]);

  if (["invalid", "loading"].includes(experts.status)) {
    return <PageLoader />;
  }

  if (experts?.status === "failure") {
    return <p>Error</p>;
  }

  const { data, meta } = experts;

  return (
    <Main breadcrumbs={[{ name: "Eksperci" }]} title="Eksperci">
      <div className="dashboard">
        <header className="dashboard__header">
          <h2 className="dashboard__title heading">Zarządzanie ekspertami</h2>
          <Button
            type="add"
            text="Dodaj eksperta"
            onClick={() => history.push("/experts/new")}
          />
        </header>
        <Search
          action={(page, per_page, query) => {
            setQuery(query);
            getExperts({
              page,
              per_page,
              query,
              loading: false,
            });
          }}
          defaultValue={query}
        />
        {data?.length > 0 ? (
          <>
            <table className="table">
              <thead className="table__header table-head">
                <tr>
                  <th style={{ width: 120 }}>Pozycja</th>
                  <th style={{ width: 120 }}>Zdjęcie</th>
                  <th>Imię</th>
                  <th>Nazwisko</th>
                </tr>
              </thead>
              <tbody className="table__body table-body">
                {data.map(
                  ({ id, position, first_name, last_name, image_url }) => (
                    <tr key={id} onClick={() => history.push(`/experts/${id}`)}>
                      <td>{position}</td>
                      <td>
                        {image_url && (
                          <div
                            style={{
                              width: 40,
                              height: 40,
                              borderRadius: 5,
                              backgroundColor: "#93a2dd",
                              backgroundImage: `url(${image_url})`,
                              backgroundSize: "cover",
                            }}
                          />
                        )}
                      </td>
                      <td>{first_name}</td>
                      <td>{last_name}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </>
        ) : (
          <div style={{ padding: 20 }}>
            <p>Brak ekspertów</p>
          </div>
        )}
        <Pagination data={{ ...meta }} />
      </div>
    </Main>
  );
};

export default connect(
  ({ experts }) => ({ experts }),
  (dispatch) => ({
    getExperts: (data) => dispatch(getExperts(data)),
  })
)(ExpertsList);
