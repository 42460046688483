const course_module_widget_options = [
  { label: "Tekst", value: "CourseModuleWidget::Text" },
  { label: "Video", value: "CourseModuleWidget::Video" },
  {
    label: "Youtube",
    value: "CourseModuleWidget::Youtube",
  },
  {
    label: "Plik",
    value: "CourseModuleWidget::File",
  },
  {
    label: "Zdjęcie",
    value: "CourseModuleWidget::Image",
  },
];

export default course_module_widget_options;
