import React from "react";
import {
  Field,
  formValueSelector,
  reduxForm,
} from "redux-form";
import { connect } from "react-redux";
import {
  Main,
  Input,
  ActionBar,
  ImageUploader,
  Textarea,
  DatePicker,
  Select,
} from "components";
import { createArticle } from "actions/articles";

import { ReactComponent as InfoIcon } from "icons/info.svg";
import Editor from "components/EditorSimple";
import article_kind_options from "utils/options/article_kind_options";

const SelectField = (props) => <Select {...props} />;
const InputField = (props) => <Input {...props} />;
const TextAreaField = (props) => <Textarea {...props} />;

const selector = formValueSelector("create_article");

const required_fields = [
  "title",
  "meta_title",
  "meta_description",
];

const validate = (values) => {
  const errors = {};
  required_fields.forEach((key) => {
    if (!values[key]) {
      errors[key] = "Wymagane";
    }
  });

  return errors;
};

const CreateArticle = ({
  handleSubmit,
  history,
  change,
  date,
}) => {
  const onSubmit = async (values) => {
    try {
      const id = await createArticle(values);
      history.push(`/articles/${id}`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Main
      breadcrumbs={[
        {
          name: "Artykuły",
          href: "/articles",
        },
        { name: "Dodaj artykuł" },
      ]}
      title="Dodaj artykuł"
    >
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <div className="dashboard">
            <header className="dashboard__header">
              <div className="icon__container">
                <InfoIcon />
              </div>
              <h2 className="dashboard__title heading">
                Podstawowe informacje
              </h2>
            </header>
            <div className="dashboard__form">
              <form noValidate>
                <Field
                  label="Rodzaj"
                  name="kind"
                  component={SelectField}
                  options={article_kind_options}
                  handleChange={(value) =>
                    change("kind", value)
                  }
                  defaultValue={article_kind_options[0]}
                />
                <DatePicker
                  label="Data"
                  value={date}
                  onChange={(value) =>
                    change("date", value)
                  }
                />
                <Field
                  label="Tytuł"
                  name="title"
                  component={InputField}
                />
                <Field
                  label="Meta title"
                  name="meta_title"
                  component={InputField}
                />
                <Field
                  label="Meta description"
                  name="meta_description"
                  component={InputField}
                />
                <Field
                  label="Krótki opis (do kafelka)"
                  name="short_description"
                  component={TextAreaField}
                />
                <Editor
                  label="Treść"
                  name="content"
                  change={change}
                  value=""
                />
              </form>
            </div>
          </div>
        </div>
        <div className="column-half">
          <div className="dashboard">
            <header className="dashboard__header">
              <div className="icon__container">
                <InfoIcon />
              </div>
              <h2 className="dashboard__title heading">
                Miniaturka
              </h2>
            </header>
            <div className="dashboard__form">
              <ImageUploader
                handleFile={(file) => change("image", file)}
              />
            </div>
          </div>
        </div>
      </div>
      <ActionBar
        saveAction={handleSubmit(onSubmit)}
        cancel_path="/articles"
      />
    </Main>
  );
};

export default connect((state) => ({
  initialValues: {
    date: new Date().toDateString(),
    kind: 'article',
  },
  date: selector(state, "date"),
}))(
  reduxForm({
    form: "create_article",
    enableReinitialize: true,
    validate,
  })(CreateArticle)
);
