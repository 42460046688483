import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import {
  Search,
  Pagination,
  Main,
  PageLoader,
  Button,
} from "components";
import { getCourses } from "actions/courses";

const CoursesList = ({
  history,
  location: { search },
  courses,
  getCourses,
}) => {
  const {
    page,
    per_page,
    query: default_query,
  } = queryString.parse(search);

  const [query, setQuery] = useState(default_query || "");

  useEffect(() => {
    getCourses({ page, per_page, query });
  }, [page, per_page]);

  if (
    courses.status === "invalid" ||
    courses.status === "loading"
  ) {
    return <PageLoader />;
  }

  const { data, meta } = courses;

  return (
    <Main
      breadcrumbs={[{ name: "Baza wiedzy" }]}
      title="Baza wiedzy">
      <div className="dashboard">
        <header className="dashboard__header">
          <h2 className="dashboard__title heading">
            Zarządzanie kursami
          </h2>
          <Button
            type="add"
            text="Dodaj"
            onClick={() => history.push("/courses/new")}
          />
        </header>
        <Search
          action={(page, per_page, query) => {
            setQuery(query);
            getCourses({
              page,
              per_page,
              query,
              loading: false,
            });
          }}
          defaultValue={query}
        />
        {data?.length > 0 ? (
          <>
            <table className="table">
              <thead className="table__header table-head">
                <tr>
                  <th>Tytuł</th>
                  <th>Slug</th>
                </tr>
              </thead>
              <tbody className="table__body table-body">
                {data.map(({ id, title, slug }) => (
                  <tr
                    key={id}
                    onClick={() =>
                      history.push(`/courses/${id}`)
                    }>
                    <td>{title}</td>
                    <td>{slug || "-"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        ) : (
          <div style={{ padding: 20 }}>
            <p>Brak kursów</p>
          </div>
        )}
        <Pagination data={{ ...meta }} />
      </div>
    </Main>
  );
};

export default connect(
  ({ courses }) => ({ courses }),
  (dispatch) => ({
    getCourses: (data) => dispatch(getCourses(data)),
  })
)(CoursesList);
