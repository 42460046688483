import React, { PureComponent } from "react";
class Search extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.timeout = null;
  }

  handleChange = (value) => {
    clearTimeout(this.timeout);
    const { action } = this.props;
    this.timeout = setTimeout(() => {
      action(1, 10, value, false);
    }, 500);
  };
  render() {
    return (
      <form
        className="dashboard__searchfield"
        onSubmit={(e) => e.preventDefault()}
      >
        <div className="form-group">
          <div className="inp-wrapper">
            <div className="inp-group">
              <input
                onChange={({ target: { value } }) => this.handleChange(value)}
                className="search-box"
                type="search"   
                defaultValue={this.props.defaultValue}
                placeholder="Szukaj..."
              />
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default Search;
