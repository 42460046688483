import React from "react";
import Login from "pages/Login";
import { Switch, Route } from "react-router-dom";

const Auth = () => {
  return (
    <Switch>
      <Route component={Login} />
    </Switch>
  );
};

export default Auth;
