import React from "react";

const Textarea = ({
  meta,
  input,
  label = null,
  minHeight = 100,
  disabled,
}) => {
  return (
    <div
      className={`form-group ${meta.touched && meta.valid ? "visited" : ""} ${
        meta.touched && meta.invalid ? "error" : ""
      }`}
    >
      <div className="textarea-wrapper">
        {label && (
          <label htmlFor={input.name} className="label-form">
            {label}
          </label>
        )}

        <div className={`textarea-group`}>
          <textarea
            className={disabled ? "textarea-disabled" : ""}
            disabled={disabled}
            id={input.name}
            {...input}
            style={{ minHeight }}
          />
        </div>
      </div>
      {meta.touched && meta.invalid && meta.error && (
        <p className="inp-error">{meta.error}</p>
      )}
    </div>
  );
};

export default Textarea;
